import React from 'react';


const PreviewAttest = (props) => {
    return (
        <div className="flow-option-block">
            <h5>Certification Statement</h5><br></br><br></br>
            <p>
                By signing the below, I certify that I have read and agree to the Terms and Conditions of my coupon.
                <br></br><br></br>
                I certify that I personally paid or have responsibility to pay the co-pay required by my prescription/medical
                insurer for the product for
                which I am seeking reimbursement (or I have made a full cash payment if I am privately insured but my
                insurer does not provide coverage for the product).
                <br></br><br></br>
                I certify that the information provided in this request is accurate, that expenses requested for payment
                were not and will not be paid by any other source, including but not limited to my insurance, my
                Flexible Spending Account (FSA), or Health Savings Account (HSA), or any other type of medical savings
                account. I am not covered by any state or federally funded programs, including but not limited to
                Medicare, Medicaid, Medigap, VA, DOD, TriCare or where otherwise prohibited by federal or state law.
                <br></br><br></br>
                I certify that I am a resident of United States, Puerto Rico, Guam or Virgin Island.
                <br></br><br></br>
                I understand that the information provided here will be available to RxCrossroads by McKesson
                Corporation ("McKesson"), the administrator of this program, and to others working on behalf of
                McKesson, whose access is necessary for data processing, eligibility verification, and follow-up related
                to this program. McKesson may also provide this information as allowed by law to the manufacturer sponsoring the copay program.
                <br></br><br></br>
                By signing below, I agree that McKesson, and others working on behalf of McKesson, are authorized to
                verify this information and use it to verify my compliance with the Terms and Conditions of this program
                and to conduct fraud prevention processes.
                <br></br><br></br>
                I further acknowledge that all information including attachments submitted are complete and accurate.
                <br></br><br></br>
            </p>


            <div className="clear">
                <div className="m-block fl two-col-spaced   ">
                    Patient Signature
                    <br></br>

                    {props.patientInfo.font1 ?
                        <span className="attest_font1"> {props.patientInfo.signature}  </span> : <></>}
                    {props.patientInfo.font2 ?
                        <span className="attest_font2"> {props.patientInfo.signature}</span> : <></>}
                    {props.patientInfo.font3 ?
                        <span className="attest_font3"> {props.patientInfo.signature}  </span> : <></>}
                </div>
                <div className="m-block fl two-col-spaced ">
                    <div className="m-block fl ">
                        Relationship to Patient
                        <br></br>

                        {props.patientInfo.font1 ?
                            <span className="attest_font1"> {props.patientInfo.relationship}  </span> : <></>}
                        {props.patientInfo.font2 ?
                            <span className="attest_font2"> {props.patientInfo.relationship}  </span> : <></>}
                        {props.patientInfo.font3 ?
                            <span className="attest_font3"> {props.patientInfo.relationship}  </span> : <></>}

                    </div>
                    <div className="m-block fr two-sm-col-spaced-r  ">
                        Date:

                        <br></br>
                        {props.patientInfo.font1 ?
                            <span className="attest_font1"> {props.patientInfo.signdate}  </span> : <></>}
                        {props.patientInfo.font2 ?
                            <span className="attest_font2"> {props.patientInfo.signdate}  </span> : <></>}
                        {props.patientInfo.font3 ?
                            <span className="attest_font3"> {props.patientInfo.signdate}  </span> : <></>}
                    </div>
                </div>

            </div>

        </div>
    );

}
export default PreviewAttest