import React, {useState} from 'react';
//import { useField } from 'formik';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import DateFnsUtils from "@date-io/date-fns";


const SignatureDate = ({label, readOnlyFlag, ...props}) => {
    //const [field, meta] = useField(props);
    //const [err, setErr] = useState(false);
    //const [xx, setXX] =useState(true);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateChange = date => {
        setSelectedDate(new Date());
    };

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    label={label}
                    format="MM/dd/yyyy"
                    clearable
                    readOnly={readOnlyFlag}
                    disableFuture
                    name={props.name}
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>


        </>


    );
};

export default SignatureDate