import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class ErrorBoundry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        console.log(errorInfo);
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (

                <div className="header-wrap ">
                    <br></br> <br></br> <br></br>
                    <ErrorOutlineIcon fontSize="large"/> <br></br>
                    <h2> Something went wrong. Click &nbsp;
                        <a href="/web/">
                            <span>Home</span>
                        </a>
                    </h2>

                </div>


            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundry