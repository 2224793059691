import React from 'react';

import StepsImageThree from '../common/StepsImageThree'
import PreviewProgramInformation from './PreviewProgramInformation';
import PreviewPatientInformation from './PreviewPatientInformation'

import PreviewPharmacyInformation from './PreviewPharmacyInformation';
import PreviewPrescriptionInformation from './PreviewPrescriptionInformation';

import PreviewAttest from './PreviewAttest';

import PreviewUpload from './PreviewUpload';


//const patientInfo = useSelector(state => state.patientInfo);


class PreviewAndSubmitPrint extends React.Component {
    render() {
        return (

            <div style={{margin: "1em"}}>
                <div className="main-content-wrapper">
                    <div className="nine-tenth-mobile block-center">


                        <div className="m-block  padding-r-lg ">
                            <StepsImageThree></StepsImageThree><br></br>
                            <h1 className="modal-title"> PREVIEW &#38; SUBMIT </h1><br></br>
                            <h4> Please preview your information and confirm prior to submitting. You may edit the
                                information
                                by navigating to the previous pages using the Back button.

                            </h4>  <br></br>
                            <PreviewProgramInformation patientInfo={this.props.patientInfo}></PreviewProgramInformation>
                            <PreviewPatientInformation patientInfo={this.props.patientInfo}></PreviewPatientInformation>
                            {this.props.patientInfo.flowName !== 'UPLOAD_RECEIPT' ? 
                                    <>
                                         <PreviewPharmacyInformation
                                         patientInfo={this.props.patientInfo}></PreviewPharmacyInformation>
                                     
                                        <PreviewPrescriptionInformation
                                         patientInfo={this.props.patientInfo}></PreviewPrescriptionInformation>
                                    </>
                                :
                                    <>
                                    </>         
                        
                            }
                           <PreviewUpload patientInfo={this.props.patientInfo}></PreviewUpload>
                            <PreviewAttest patientInfo={this.props.patientInfo}></PreviewAttest>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default PreviewAndSubmitPrint