import React from 'react';


const StepsImage = () => {

    return (

        <div className="steps"></div>

    );

}

export default StepsImage