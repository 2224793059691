import React from 'react';

const PrivacyPolicy = () => {
    return (

        <div className="main-content-wrapper">
            <div className="nine-tenth-mobile block-center">
                <h1 className="modal-title"> Privacy Policy <br></br>Effective Date: September 21, 2018 </h1><br></br>
                <div className="flow-option-block">

                    At McKesson Specialty Health ("McKesson" or "we"), we value the trust that patients place in us to
                    appropriately use and protect personal information. This Privacy Notice users of McKesson's Patient
                    Rebate Online portal ("portal") about the purposes for which McKesson may collect, use and share
                    personal information and how it is protected. We want you to be clear how we're using personal
                    information and the ways in which we protect this information. By using this portal, you accept the
                    privacy practices presented in this Privacy Notice. We encourage you to read this Privacy Notice in
                    full
                    to understand our privacy practices before using this portal or submitting any personal information.
                    <br></br><br></br>
                    <strong>Scope</strong> <br></br><br></br>
                    This Privacy Notice applies only to the <a href="/web/" target="_blank">Patient Rebate Online
                    portal</a>. McKesson affiliates and
                    subsidiaries may have separate websites and services through other web, mobile, cloud or SaaS
                    platforms
                    which are not subject to this Privacy Notice. Additionally, McKesson business partners, ad networks
                    and
                    other third parties may have their own websites and services with separate privacy practices. We
                    encourage you to read the privacy notices of all websites and services you visit to understand their
                    privacy practices and your options.
                    <br></br><br></br>
                    <strong>Personal Information We Collect </strong> <br></br><br></br>
                    When you use this portal, we may collect personal information about you. Personal information is any
                    information accessed, collected, or used by McKesson that identifies an individual, or can
                    reasonably be
                    used to identify an individual, whether directly or indirectly. The personal information we may
                    collect
                    includes, but is not limited to:
                    <br></br><br></br>
                    <ul>
                        <li>Name</li>
                        <li>Personal characteristics, such as date of birth and gender</li>
                        <li>Contact information, such as mailing address and phone number</li>
                        <li> Health-related information, such as prescription number, prescription price, co-pay amount,
                            health insurance identification number, medication name, strength and quantity
                        </li>
                        <li>Electronic Signature</li>
                        <li> Unique identifiers and user behavioral information collected passively, such as your IP
                            address,
                            location, browser type, operating system and clickstream activity
                        </li>
                    </ul>

                    <strong>Tracking Technologies We Use </strong>
                    <br></br><br></br>
                    We use various technologies to collect personal information about of this portal. These technologies
                    include the following: <br></br><br></br>
                    <ul>
                        <li> Web server logs
                            <ul>
                                <li>As is true of most websites, we gather certain information automatically and store
                                    it in log
                                    files. This information may include IP addresses, browser type, internet service
                                    provider,
                                    referring/exit pages, operating system, date/time stamp and/or clickstream data.
                                </li>
                            </ul>
                        </li>

                        <li>Cookies
                            <ul>
                                <li>We may utilize cookies. Cookies uniquely identify your device or user account
                                    associated with
                                    this portal. You can control the use of cookies by adjusting your browser
                                    preferences at any
                                    time. If you reject cookies, you may still use this portal, but your ability to use
                                    some
                                    features or areas of the portal may be limited.
                                </li>
                            </ul>
                        </li>

                        <li>HTML5
                            <ul>
                                <li>
                                    We also local storage such as HTML5 to collect and store content information and
                                    preferences.
                                    Third parties who we partner with to provide certain features on this portal use
                                    local storage
                                    such as HTML5 to collect and store information. Various browsers may offer their own
                                    management
                                    tools for removing HTML5 local storage.
                                </li>
                            </ul>
                        </li>
                        <li>Other Tracking Technologies
                            <ul>
                                <li>
                                    We may also use technologies such as scripts and tags.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    These technologies are used to gather demographic information and to perform data analysis and
                    audits.
                    We may receive reports based on the use of these technologies from companies such as Google
                    Analytics on
                    an individual as well as aggregated basis. To learn more about Google's privacy practices, <a
                    href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">click
                    here</a>.
                    If you want to opt-out of your data being used by Google Analytics, <a
                    href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">click
                    here</a>.
                    <br></br><br></br>
                    <strong>How We Respond to "Do Not Track" Signals </strong> <br></br><br></br>
                    Some web browsers have a "Do Not Track" feature. This feature allows you to tell websites you visit
                    that
                    you do not want to have your online activity tracked over time and across websites. These features
                    are
                    not yet uniform across browsers and not broadly supported. This portal is not currently set up to
                    respond to those signals.

                    <br></br><br></br>
                    <strong>How We Use the Personal Information Collected </strong>
                    <br></br><br></br>
                    We may use the personal information we collect for purposes such as: <br></br><br></br>
                    <ul>
                        <li> Customer and troubleshooting support</li>
                        <li>Payment processing services</li>
                    </ul>

                    <strong>How We Share Personal Information Collected </strong> <br></br><br></br>
                    <ul>
                        <li> McKesson does not sell personal information about you to third parties.</li>
                        Some of our pages may utilize framing techniques to serve content to/from our partners while
                        preserving the look and feel of our website. Please be aware that you are providing personal
                        information to these third parties and not directly to McKesson. When framing techniques are
                        utilized,
                        your personal information is subject to the privacy practices of the third party.

                    </ul>

                    McKesson may also need to share information with companies, organizations or individuals outside of
                    McKesson if we have a good faith belief that access, use, preservation, or disclosure of that
                    information is reasonably necessary to:
                    <br></br><br></br>
                    <ul>
                        <li> Meet applicable laws, regulations, legal processes or enforceable governmental requests
                        </li>
                        <li>Enforce applicable Terms of Service, including investigation of potential violations</li>
                        <li>Detect, prevent, or otherwise address fraud, security or technical issues</li>
                        <li>Protect against harm to the rights, property or safety of our users, McKesson, or the public
                            as
                            required or permitted by law
                        </li>
                        <li>Engage in a merger, acquisition, reorganization, or sale of all or a portion of McKesson
                            assets.
                        </li>
                    </ul>
                    <strong>Choice </strong>
                    <br></br><br></br>
                    Where appropriate or legally required, we will describe how your information that we collect through
                    this portal will be used and will provide choices about whether to allow us to engage in that use.
                    To
                    exercise your choice, you may request to access, correct, update or delete information about you by
                    contacting <a href="mailto:Privacy@McKesson.com">Privacy@McKesson.com</a>.
                    <br></br><br></br>
                    <strong>Retention </strong>
                    <br></br><br></br>
                    We will retain your personal information for as long as your account is active, as reasonably useful
                    for
                    commercial purposes, or as necessary to comply with our legal obligations, resolve disputes and
                    enforce
                    our agreements.
                    <br></br><br></br>
                    <strong>Health Insurance Portability and Accountability Act ("HIPAA") </strong>
                    <br></br><br></br>
                    As a key provider of services and technology to the healthcare industry, McKesson has implemented
                    programs to address the privacy and security requirements of HIPAA.
                    <br></br><br></br>
                    <strong>Children's Privacy </strong>
                    <br></br><br></br>
                    This portal is not intended for any user under the age of 13, and we do not knowingly collect
                    personal
                    information from children under the age of 13. We request that children under the age of 13 not
                    submit
                    any personal information through this portal.
                    <br></br><br></br>
                    <strong>How We Protect Your Information </strong>
                    <br></br><br></br>
                    McKesson has established appropriate physical, electronic and administrative safeguards to protect
                    the
                    information we collect from or about our users. We restrict access to personal information to
                    McKesson
                    employees, contractors and agents who need to know that information to process it for us, and who
                    are
                    subject to confidentiality obligations.
                    <br></br><br></br>
                    Any sensitive personal information (e.g., protected health information) will be transmitted in an
                    encrypted form using SSL encryption. Notwithstanding our security safeguards, it is impossible to
                    guarantee 100% security in all circumstances. If you have any questions about security or have
                    reason to
                    believe that your interaction with us is no longer secure (for example, you feel that the security
                    of
                    any account you might have with us has been compromised), you must immediately notify us of the
                    problem
                    by contacting McKesson at Privacy@McKesson.com.
                    <br></br><br></br>
                    <strong>Changes to this Privacy Notice </strong>
                    <br></br><br></br>
                    We may periodically update this Privacy Notice to describe new features, products or services we
                    offer
                    and how it may affect our use of personal information about you and your controls. Since we may
                    change
                    this Privacy Notice, we recommend that you check the current version available from time to time. If
                    we
                    make changes to this notice, we will update the "Effective Date" at the beginning of this notice.
                    <br></br><br></br>
                    <strong>Contact Information </strong>
                    <br></br><br></br>
                    If you have questions or concerns about this Privacy Notice, our information handling practices, or
                    any
                    other aspect of privacy and security of your personal information, please contact us at:
                    <a href="mailto:Privacy@McKesson.com"> Privacy@McKesson.com</a>.
                    <br></br><br></br>
                    You may also write to us at:

                    <p className="address-set">McKesson Corporation<br></br>
                        ATTN: Privacy Officer (Privacy Notice, MSH Patient Rebate Online)<br></br>
                        2 National Data Plaza NE, 3<sup>rd</sup> Floor<br></br>
                        Atlanta, GA 30329 </p>
                    <br></br> <br></br>
                </div>
            </div>
        </div>
    );

}
export default PrivacyPolicy