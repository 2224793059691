import React, {useState} from 'react';
import {useField} from 'formik';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


var statesList = [
    {"code": "AL", "name": "Alabama"},
    {"code": "AK", "name": "Alaska"},
    // { "code": "AS", "name": "American Samoa" },
    {"code": "AZ", "name": "Arizona"},
    {"code": "AR", "name": "Arkansas"},
    {"code": "CA", "name": "California"},
    {"code": "CO", "name": "Colorado"},
    {"code": "CT", "name": "Connecticut"},
    {"code": "DE", "name": "Delaware"},
    {"code": "DC", "name": "District Of Columbia"},
    {"code": "FL", "name": "Florida"},
    {"code": "GA", "name": "Georgia"},
    {"code": "GU", "name": "Guam"},
    {"code": "HI", "name": "Hawaii"},
    {"code": "ID", "name": "Idaho"},
    {"code": "IL", "name": "Illinois"},
    {"code": "IN", "name": "Indiana"},
    {"code": "IA", "name": "Iowa"},
    {"code": "KS", "name": "Kansas"},
    {"code": "KY", "name": "Kentucky"},
    {"code": "LA", "name": "Louisiana"},
    {"code": "ME", "name": "Maine"},
    {"code": "MD", "name": "Maryland"},
    {"code": "MA", "name": "Massachusetts"},
    {"code": "MI", "name": "Michigan"},
    {"code": "MN", "name": "Minnesota"},
    {"code": "MS", "name": "Mississippi"},
    {"code": "MO", "name": "Missouri"},
    {"code": "MT", "name": "Montana"},
    {"code": "NE", "name": "Nebraska"},
    {"code": "NV", "name": "Nevada"},
    {"code": "NH", "name": "New Hampshire"},
    {"code": "NJ", "name": "New Jersey"},
    {"code": "NM", "name": "New Mexico"},
    {"code": "NY", "name": "New York"},
    {"code": "NC", "name": "North Carolina"},
    {"code": "ND", "name": "North Dakota"},
    {"code": "OH", "name": "Ohio"},
    {"code": "OK", "name": "Oklahoma"},
    {"code": "OR", "name": "Oregon"},
    {"code": "PA", "name": "Pennsylvania"},
    {"code": "PR", "name": "Puerto Rico"},
    {"code": "RI", "name": "Rhode Island"},
    {"code": "SC", "name": "South Carolina"},
    {"code": "SD", "name": "South Dakota"},
    {"code": "TN", "name": "Tennessee"},
    {"code": "TX", "name": "Texas"},
//  { "code": "UM", "name": "U.S. Minor Outlying Islands" },
    {"code": "VI", "name": "U.S. Virgin Islands"},
    {"code": "UT", "name": "Utah"},
    {"code": "VT", "name": "Vermont"},
    {"code": "VA", "name": "Virginia"},
    {"code": "WA", "name": "Washington"},
    {"code": "WV", "name": "West Virginia"},
    {"code": "WI", "name": "Wisconsin"},
    {"code": "WY", "name": "Wyoming"},


];

// const setStateVal = (value) => {
//   let name = null;
//   statesList.forEach(state => {
//     if (state.code === value) {
//       name = state.name;
//     }
//   }
//   );
//   return name;
// }

// const setStateCode = (value) => {
//   let name = null;
//   statesList.forEach(state => {
//     if (state.name === value) {
//       name = state.code;
//     }
//   }
//   );
//   return name;
// }


const chkReadOnly = (val) => {
    let chk = false;
    if (val === true) {
        chk = true;
    }
    return chk;
}

const StateSelect = ({label, readOnlyFlag, ...props}) => {

    const [field, meta] = useField(props);
    const [err, setErr] = useState(false);
    //const [state, setState] = useState();
    const [xx, setXX] = useState(true);


    const handleChange = (event) => {
        setXX(false);
        props.parentprops.setFieldValue(props.name, event.target.value, false);
        if (event.target.value !== "") {
            setErr(false);
        } else {
            setErr(true);
        }
    };

    return (
        <TextField   {...field} {...props}
                     label={label}
                     id={props.name}
                     select
                     inputProps={{readOnly: chkReadOnly(readOnlyFlag)}}
                     name={props.name}
                     value={meta.value}
                     onChange={handleChange}
            // helperText={err ? "select select" : ""}
            // error={err ? true : false}
                     helperText={(xx && meta.error) || err ? meta.error : ""}
                     error={(xx && meta.error) || err ? true : false}
        >
            {statesList.map((state) => (
                <MenuItem key={state.code} value={state.code} selected={true}>
                    {state.name}
                </MenuItem>
            ))}
        </TextField>

    )

};

export default StateSelect