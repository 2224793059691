const url = "https://patientrebateonline-dev.dev.cs.west.us.mckesson.com/api/";

export const variables = {
    card: url + 'card',
    lock: url + 'lock',
    lockStatus: url + 'patientLockStatus',
    name: url + 'name',
    online: url + 'online',
    validate: url + 'validate',
    eventLookup: url + 'patients/event',
    deleteFile: url + 'mail/delete/',
    mail: url + 'mail',
    dataDomainScriptId: '0190c01f-3191-7fa2-ad6e-a312399b5498-test',
    reCaptcha: "6LdRMrEZAAAAAOGHzh8THiIJrQL_xgoW0DDsHqJv",
    plaintext: "PatientRebatePortal",
    x1: 309593752,
    x2: 1451513464,
    x3: 8,
    y1: 1131376737,
    y2: 2034525558,
    y3: 1701605232,
    y4: 12,
    selfServiceUrl: "https://mprsdev.mckesson.com/selfservice/#",
    privacyPolicyLink: "https://mprsdev.mckesson.com/copayselfservice/privacypolicy.html#"
};