import React from 'react';
import Button from '@material-ui/core/Button';
import { UserTypeIcon1, UserTypeIcon2 } from './UserTypeIcons';
import {useHistory} from "react-router-dom";
import {setEnvVariables} from "../../config/EnvUtil";


const LandingPage = () => {

    let history = useHistory();
    let endpoint = setEnvVariables(window.location.hostname);

    return (

        // history.push('/web/Home')
        <div className="main-content-wrapper">
        <div className="nine-tenth-mobile block-center">
            <div className="clear" >

                <div className="m-block fl two-col padding-r-lg">
                    <h1> Welcome to RxCrossroads <br></br> by McKesson's Portal!  </h1> <br></br>
                </div>
                 <div className="m-block block-center  padding-r-xs">
                    <div className="two-col-spaced fl">
                        <UserTypeIcon1  />
                        <Button id="card-btn" onClick={() => history.push('/web/Home')} ><span className="button btn-primaryTwo" >I am a patient</span></Button>
                    </div>  
                    <div className="two-col-spaced fl">
                        <UserTypeIcon2 />
                        <Button id="no-card-btn" onClick={() => window.open(endpoint.selfServiceUrl)}><span className="button btn-primaryTwo" >I am a pharmacist for Copay self service</span></Button>
                    </div>
                </div>
            </div>
            <div className="user-type-spacer"></div>
        </div>
    </div>
    );
}
export default LandingPage; 