import React from 'react';


const PreviewPharmacyInformation = (props) => {
    return (

        <div className="flow-option-block">

            <div className="clear">

                <h3>Pharmacy/Provider Information</h3> <br/>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider Name</div>

                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.pharmacyname} </p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider Address 1</div>

                            <div className="preview-value"><p
                                className="preview-text">    {props.patientInfo.pharmacyaddress1} </p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider Address 2</div>

                            <div className="preview-value"><p
                                className="preview-text">    {props.patientInfo.pharmacyaddress2}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider City</div>

                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.pharmacycity}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider State</div>

                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.pharmacystate}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Pharmacy/Provider Zip Code</div>

                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.pharmacyzip}</p></div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );

}
export default PreviewPharmacyInformation