import CryptoJS from 'crypto-js/crypto-js';

const formatString = (dob) => {

    var dd = dob.getDate();
    var mm = dob.getMonth() + 1;
    var yyyy = dob.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return ('15' + mm + '06' + dd + '37' + yyyy + '84');

}

export const encryptFunction = (endpoint) => {


    let keywords = CryptoJS.lib.WordArray.create([endpoint.x1, endpoint.x2], endpoint.x3);
    let key = CryptoJS.enc.Latin1.parse(CryptoJS.enc.Hex.stringify(keywords));


    let textWord = CryptoJS.lib.WordArray.create([endpoint.y1, endpoint.y2, endpoint.y3], endpoint.y4);
    let data = CryptoJS.enc.Utf8.stringify(textWord);


    //let  iv = '01';
    let z = formatString(new Date());
    //console.log(z);
    let iv = CryptoJS.enc.Latin1.parse(z);
    var encrypted = CryptoJS.AES.encrypt(
        data,
        key,
        {
            iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding
        });

    // var decrypt = CryptoJS.AES.decrypt(encrypted.toString(), key, {iv: iv, padding: CryptoJS.pad.ZeroPadding, mode: CryptoJS.mode.CBC});
    // console.log('decrypted: ' +CryptoJS.enc.Utf8.stringify(decrypt).toString());

    let authtoken = {
        "data": btoa(encrypted.toString()),
        "token": btoa(z),
    }


    return authtoken;
}