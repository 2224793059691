import React from 'react';

const Faqs = () => {
    return (

        <div className="main-content-wrapper">
            <div className="nine-tenth-mobile block-center">
                <h1 className="modal-title"> Patient Rebate Frequently Asked Questions: </h1><br></br>
                <div className="flow-option-block">
                    <h5>What are my refund options for reimbursement?</h5>
                    <h4>Payment will be issued by paper check via mail.
                    </h4>   <br></br>
                    <h5>What type of payments are excluded for reimbursement?</h5>
                    <h4>Any Medical Savings Account/Flex Spending Account or Health Savings Account.</h4>
                    <br></br>
                    <h5>Can I track my rebate status?</h5>
                    <h4>Yes. By contacting McKesson Pharmacy Support Team (PST) representative at 1-866-566-6446.</h4>
                    <br></br>
                    <h5>Can I upload my missing documents online?</h5>
                    <h4>Yes. Documents can be uploaded <a href="/web/">here</a>.</h4>
                    <br></br>
                    <h5>Is there any rebate confirmation number for my own record?</h5>
                    <h4>Patient ID number can be used to verify claim activity.</h4>
                    <br></br>
                    <h5>Can I receive my rebate via direct deposit?</h5>
                    <h4>Only specific programs allow Electronic Fund Transfers (EFT). Please contact a Pharmacy Support
                        Team Representative for more information
                        at 1-866-566-6446.</h4>
                    <br></br>
                    <h5>What are acceptable forms of proof of payment?</h5>
                    <h4>A) Invoice that shows paid amount <br></br>
                        B) Pharmacy Statement of account that shows paid amount <br></br>
                        C) Receipt or credit card statement that shows card holder name and account type</h4>
                    <br></br>
                    <h5>What documents are required for prescription information on my rebate?</h5>
                    <h4>Anything showing Medication Name or NDC, RX #, Quantity and day supply, patient and pharmacy
                        name, and lastly Date the Prescription was filled, and a copy of the label from the bottle or
                        box.</h4>
                    <br></br>
                    <h5>Does the rebate require a copy of my insurance card?</h5>
                    <h4>Yes. A copy of the front and back of the insurance card is required for each claim</h4>
                    <br></br>
                    <h5>What is an EOB and why are we requesting it?</h5>
                    <h4>An EOB (Explanation of Benefits) is a statement sent by the health insurance company explaining
                        what medical treatments or services were paid for on their behalf.</h4>
                    <br></br>
                    <h5>How do I upload documents?</h5>
                    <h4>A) Use your phone to take a picture of prescription insurance card, Prescription label and/or
                        receipt <br></br>
                        B) Use browse button to locate the file to upload <br></br>
                        C) You need to use the browse button each time to upload one file at a time</h4>
                    <br></br>
                    <h5>Why can’t I receive reimbursement to my HSA/FSA?</h5>
                    <h4>Savings accounts are pretax dollars, they cannot be reimbursed.</h4>
                    <br></br> <br></br>
                </div>
            </div>
        </div>
    );

}
export default Faqs