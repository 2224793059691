import React from 'react';

const PreviewPatientInformation = (props) => {
    return (
        <>

            <div className="flow-option-block">

                <div className="clear">

                    <h3>Patient Information</h3>
                    <br/>
                    <div className="insurance-info-form">
                        <div className="clear">
                            <div className="clear">
                                <div className="fl m-block   padding-r-md m-p-0">
                                    <div className="field-wrap preview-box">
                                        <div className="preview-label"> Patient Address 1</div>


                                        <div className="preview-value"><p
                                            className="preview-text">{props.patientInfo.address.addressLine1}</p></div>
                                    </div>
                                </div>

                                <div className="clear">
                                </div>
                                <div className="fl m-block   padding-r-md m-p-0">
                                    <div className="field-wrap preview-box">
                                        <div className="preview-label"> Patient Address 2</div>

                                        <div className="preview-value"><p
                                            className="preview-text">{props.patientInfo.address.addressLine2}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear">
                            <div className="fl m-block   padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label">Patient City</div>

                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.address.city}</p></div>
                                </div>
                            </div>
                        </div>

                        <div className="clear">

                            <div className="fl m-block   padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label">Patient State</div>

                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.address.state}</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="clear">
                            <div className="fl m-block    padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label">Patient Zip Code</div>

                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.address.zip}-{props.patientInfo.address.zipExtn} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear">
                            <div className="fl m-block   padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label"> Patient Phone Number</div>

                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.phoneNumber}</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="clear">
                            <div className="fl m-block   padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label"> Patient Email Address</div>

                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.email}</p></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );

}
export default PreviewPatientInformation