import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";

import {Formik} from "formik";
import * as yup from "yup";

import {useDispatch, useSelector} from 'react-redux';
import {SAVE_PATIENT_INFO} from "../../action/PatientLokUpAction";

import StepsImageTwo from '../common/StepsImageTwo'
import MyTextInput from "../common/MyTextInput";
import RelationSelect from "../common/RelationSelect";
import SignatureDate from "../common/SignatureDate";

import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import AddIcon from '@material-ui/icons/Add';
//import AttachFileIcon from '@material-ui/icons/AttachFile';
//import PhotoCamera from '@material-ui/icons/PhotoCamera';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DeleteIcon from '@material-ui/icons/Delete';
import docx from '../../icons/docx.png';
import pdf from '../../icons/pdf.png';
import tiff from '../../icons/tiff.png';


const UploadAndAttest = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        input: {
            display: 'none',
        },
        paper: {
            height: 70,
            width: 70,

            paddingLeft: '12px',
        },
        emptypaper: {
            height: 70,
            paddingLeft: '12px',
        },
        span: {
            paddingLeft: '12px',
        },
        span1: {
            color: '#5a8e22',
        },
        image: {
            width: 70,
            height: 70,
        },
    }));

    const classes = useStyles();
    const location = useLocation();
    const patientInfo = useSelector(state => state.patientInfo);
    const fileList = patientInfo.fileList;
    const dispatch = useDispatch();

    // Date to String(MM/DD/YYYY) conversion
    const dateFormat = (dob) => {
        var dd = dob.getDate();
        var mm = dob.getMonth() + 1;
        var yyyy = dob.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return (mm + '/' + dd + '/' + yyyy);
    }

    // Attestaion body Starts  
    var formSchema = {
        signature: patientInfo.signature,
        relationship: patientInfo.relationship,
        fontValue: patientInfo.fontValue,
        signdate: patientInfo.signdate,
        fontselect: patientInfo.fontselect,
        fileselect: patientInfo.fileselect
    };

    //let match = useRouteMatch();
    let history = useHistory();

    const nextpage = (url) => {
        history.push(url);
        //window.location.href = "#root";
    }


    var validationSchema = yup.object().shape({
        signature: yup.string()
            .required('This field is required.')
            .max(40, 'This field should not exceed 40 characters')
            .min(2, 'This field must be minimum 2 characters.'),
        relationship: yup.string()
            .required('This field is required.'),
        fontselect: yup.boolean()
            .oneOf([true], 'This field is required.'),
        fileselect: yup.boolean()
            .oneOf([true], 'This field is required.'),
    });

    // const [font, setFont] = React.useState(false);
    const [font1, setFont1] = React.useState(patientInfo.font1);
    const [font2, setFont2] = React.useState(patientInfo.font2);
    const [font3, setFont3] = React.useState(patientInfo.font3);
    const [fileselectchk, setFileselectchk] = React.useState(false);
    const [selfchk, setSelfchk] = React.useState(true);
    let patientFName = patientInfo.firstName;
    let patientLName = patientInfo.lastName;
    let patientname = patientFName.trim() + " " + patientLName.trim();
    const updateRelation = (x) => setSelfchk(x);

    // Attestation body ends
    //File Upload Starts

    const [addchck, setaddchk] = useState(true);
    const [duplicatefile, setDuplicatefile] = useState(false);
    const [maxFileListChk, setMaxFileListChk] = useState(patientInfo.maxFileListChk);
    const [maxFileSizeChk, setMaxFileSizeChk] = useState(false);
    const [fileFormatChk, setFileFormatChk] = useState(false);
    const [eventFix, setEventFix] = useState(false);
    /* const addemptyfile = () => {
        setaddchk(true);
    } */


    const reduxSave = (filestore) => {

        dispatch({
            type: SAVE_PATIENT_INFO, payload: {
                "orgProgCamId": patientInfo.orgProgCamId,
                "orgProgCamRecipientId": patientInfo.orgProgCamRecipientId,
                "recipientId": patientInfo.recipientId,
                "email": patientInfo.email,
                "phoneNumber": patientInfo.phoneNumber,
                "programList": patientInfo.programList,
                "docList": patientInfo.docList,
                "address": patientInfo.address,
                "type": patientInfo.type,
                "programName": patientInfo.programName,
                "onlineUpload": patientInfo.onlineUpload,
                "firstName": patientInfo.firstName,
                "lastName": patientInfo.lastName,
                "dob": patientInfo.dob,
                "pharmacyname": patientInfo.pharmacyname,
                "pharmacyaddress1": patientInfo.pharmacyaddress1,
                "pharmacyaddress2": patientInfo.pharmacyaddress2,
                "pharmacycity": patientInfo.pharmacycity,
                "pharmacystate": patientInfo.pharmacystate,
                "pharmacyzip": patientInfo.pharmacyzip,
                "medicationname": patientInfo.medicationname,
                "medicationquantity": patientInfo.medicationquantity,
                "medicationdays": patientInfo.medicationdays,
                "prescriptionnum": patientInfo.prescriptionnum,
                "amountpaid": patientInfo.amountpaid,
                "reciptdate": patientInfo.reciptdate,
                "signature": patientInfo.signature,
                "relationship": patientInfo.relationship,
                "fontValue": patientInfo.fontValue,
                "signdate": patientInfo.signdate,
                "font1": font1,
                "font2": font2,
                "font3": font3,
                "fontselect": patientInfo.fontselect,
                "fileselect": patientInfo.fileselect,
                "fileList": filestore,
                "maxFileListChk": maxFileListChk,
                "eventLookup": patientInfo.eventLookup,
                "opcEventId": patientInfo.opcEventId,
                "cardId": patientInfo.cardId,
                "maxQty": patientInfo.maxQty,
                "maxDaysSupplied": patientInfo.maxDaysSupplied,
                "clientInfo": patientInfo.clientInfo,
                "activeDate": patientInfo.activeDate,
                "flowName": patientInfo.flowName,
                "customerOrgId": patientInfo.customerOrgId,
                "cardStatus": patientInfo.cardStatus,
                "startDate": patientInfo.startDate,
                "endDate": patientInfo.endDate,
                "eventLookupClinetInfo" : patientInfo.eventLookupClinetInfo,
                "startDateNameLookup": patientInfo.startDateNameLookup,
                "endDateNameLookup": patientInfo.endDateNameLookup,
                "cardStatusNameLookup" : patientInfo.cardStatusNameLookup,
                "prognameNameLookup": patientInfo.prognameNameLookup,
                "eventLookupProgramDataList": patientInfo.eventLookupProgramDataList,
                "eventLookupCardId": patientInfo.eventLookupCardId
        

            }
        });

    }


    let file;
    let fileExists;
    const addfile = (props, fileindx, event) => {

        event.preventDefault();
        file = event.target.files[0];
        fileExists = false;
        let maxFileList = 10;
        let maxFileSize = 5;
        let reader = new FileReader();
        let filename = file.name;
        let fileextension = filename.split('.').pop();
        let imagePreview;
        let fileoverride = [];
        let newfile;
        let filetypeChk = false;
        let checkreupload = false;
        let uploadinput = "uploadinput".concat(filename);
        let deleteinput = "deleteinput".concat(filename);

        setMaxFileSizeChk(false);
        setDuplicatefile(false);
        setFileFormatChk(false);
        setEventFix(!eventFix);

        reader.onloadend = () => {
            imagePreview = reader.result;
            if (fileextension.toLowerCase() === 'docx' || fileextension.toLowerCase() === 'doc') {
                imagePreview = docx;
            } else if (fileextension.toLowerCase() === 'pdf') {
                imagePreview = pdf;
            } else if (fileextension.toLowerCase() === 'tif' || fileextension.toLowerCase() === 'tiff') {
                imagePreview = tiff;
            }

            if (fileextension.toLowerCase() === 'doc' || fileextension.toLowerCase() === 'docx' || fileextension.toLowerCase() === 'pdf' || fileextension.toLowerCase() === 'jpg' ||
                fileextension.toLowerCase() === 'jpeg' || fileextension.toLowerCase() === 'tif' || fileextension.toLowerCase() === 'tiff') {
                setFileFormatChk(false);
                filetypeChk = false;
            } else {
                setFileFormatChk(true)
                filetypeChk = true;
            }

            let filesize = file.size / (1024 * 1024);


            if (filesize < maxFileSize && !filetypeChk) {
                // Second Time  reupload
                if ("newfile" !== fileindx) {
                    //alert(111);
                    checkIfFileExists(fileList);
                    fileList.forEach(element => {
                        newfile = element;
                        if (element.id === fileindx) {
                            if (!fileExists) {
                                newfile = {
                                    file: file,
                                    imagePreviewUrl: reader.result,
                                    id: file.name,
                                    chk: true,
                                    extension: fileextension,
                                    src: imagePreview,
                                    uploadinput: uploadinput,
                                    deleteinput: deleteinput
                                }
                            }
                            checkreupload = true;
                        }
                        fileoverride.push(newfile);
                    });
                }
                // First Time or new File upload
                if (!checkreupload) {
                    // alert(222);
                    fileoverride = [...fileList];
                    if (fileList.length > 0) {
                        checkIfFileExists(fileList);
                    }
                    if (!fileExists) {
                        newfile = {
                            file: file,
                            imagePreviewUrl: reader.result,
                            id: file.name,
                            chk: true,
                            extension: fileextension,
                            src: imagePreview,
                            uploadinput: uploadinput,
                            deleteinput: deleteinput
                        }
                        fileoverride.push(newfile);
                    }

                    if (fileoverride.length === maxFileList) {
                        setMaxFileListChk(false)
                    }
                }

                reduxSave(fileoverride);
                props.setFieldValue("fileselect", true, false);
                setFileselectchk(false);
            } else {
                if (!filetypeChk) {
                    setMaxFileSizeChk(true)
                }
            }

        }

        reader.readAsDataURL(file);
        // setaddchk(false);
        checkreupload = false;
    }

    const deletefile = (props, fileindx, event) => {
        setDuplicatefile(false);
        setMaxFileListChk(true);
        setMaxFileSizeChk(false);
        setFileFormatChk(false);
        setEventFix(!eventFix);
        const files = [];
        fileList.forEach(element => {
            const newfile = element;
            if (element.id !== fileindx) {
                files.push(newfile);
            }
        });


        reduxSave(files);

        if (files.length === 0) {
            setaddchk(true);
            props.setFieldValue("fileselect", false, false);
            setFileselectchk(true);
        }
    }

    // Check for duplicate files
    const checkIfFileExists = (filesList) => {
        filesList.forEach(element => {
            if (element.id === file.name) {
                fileExists = true;
                setDuplicatefile(true);
            }
        });
    }

    const trimFileName = (filename) => {
        if (filename.length > 31) {
            return filename.substring(0, 25) + "...";
        } else {
            return filename;
        }
    }

    //File Upload Ends

    return (


        <Formik
            initialValues={formSchema}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                if ((font1 || font2 || font3)) {


                    dispatch({
                        type: SAVE_PATIENT_INFO, payload: {
                            "orgProgCamId": patientInfo.orgProgCamId,
                            "orgProgCamRecipientId": patientInfo.orgProgCamRecipientId,
                            "recipientId": patientInfo.recipientId,
                            "email": patientInfo.email,
                            "phoneNumber": patientInfo.phoneNumber,
                            "programList": patientInfo.programList,
                            "docList": patientInfo.docList,
                            "address": patientInfo.address,
                            "type": patientInfo.type,
                            "programName": patientInfo.programName,
                            "onlineUpload": patientInfo.onlineUpload,
                            "firstName": patientInfo.firstName,
                            "lastName": patientInfo.lastName,
                            "dob": patientInfo.dob,
                            "pharmacyname": patientInfo.pharmacyname,
                            "pharmacyaddress1": patientInfo.pharmacyaddress1,
                            "pharmacyaddress2": patientInfo.pharmacyaddress2,
                            "pharmacycity": patientInfo.pharmacycity,
                            "pharmacystate": patientInfo.pharmacystate,
                            "pharmacyzip": patientInfo.pharmacyzip,
                            "medicationname": patientInfo.medicationname,
                            "medicationquantity": patientInfo.medicationquantity,
                            "medicationdays": patientInfo.medicationdays,
                            "prescriptionnum": patientInfo.prescriptionnum,
                            "amountpaid": patientInfo.amountpaid,
                            "reciptdate": patientInfo.reciptdate,
                            "signature": values.signature,
                            "relationship": values.relationship,
                            "fontValue": values.fontValue,
                            "signdate": dateFormat(new Date()),
                            "font1": font1,
                            "font2": font2,
                            "font3": font3,
                            "fontselect": values.fontselect,
                            "fileselect": values.fileselect,
                            "fileList": patientInfo.fileList,
                            "maxFileListChk": maxFileListChk,
                            "eventLookup": patientInfo.eventLookup,
                            "opcEventId": patientInfo.opcEventId,
                            "cardId": patientInfo.cardId,
                            "maxQty": patientInfo.maxQty,
                            "maxDaysSupplied": patientInfo.maxDaysSupplied,
                            "clientInfo": patientInfo.clientInfo,
                            "activeDate": patientInfo.activeDate,
                            "flowName": patientInfo.flowName,
                            "customerOrgId": patientInfo.customerOrgId,
                            "cardStatus": patientInfo.cardStatus,
                            "startDate": patientInfo.startDate,
                            "endDate": patientInfo.endDate,
                            "eventLookupClinetInfo" : patientInfo.eventLookupClinetInfo,
                            "startDateNameLookup": patientInfo.startDateNameLookup,
                            "endDateNameLookup": patientInfo.endDateNameLookup,
                            "cardStatusNameLookup" : patientInfo.cardStatusNameLookup,
                            "prognameNameLookup": patientInfo.prognameNameLookup,
                            "eventLookupProgramDataList": patientInfo.eventLookupProgramDataList,
                            "eventLookupCardId": patientInfo.eventLookupCardId
                    


                        }
                    });


                    //nextpage(`/web/PreviewAndSubmit`);
                    history.push({
                        pathname: '/web/PreviewAndSubmit',
                        search: location.search,
                        state: {detail: 'some_value'}
                    });
                    window.location.href = "#root";
                }

            }}
        >
            {props => (

                <form onSubmit={props.handleSubmit}>

                    <div className="main-content-wrapper" id="main-form">
                        <div className="nine-tenth-mobile block-center">
                            <div className="m-block  padding-r-lg ">
                                <StepsImageTwo></StepsImageTwo>
                                <h1 className="modal-title"> UPLOAD &#38; ATTEST </h1>
                                <h4><br></br>
                                </h4>  <br></br>

                                <div className="flow-option-block">
                                    {location.search && location.search.includes("onlineSubmission") &&
                                    <h5>The following documents are required to complete the Patient Rebate
                                        submission:<br></br>
                                        <span>Pharmacy Rebate </span>

                                        <ul>
                                            {patientInfo.docList.map(doc => (
                                                <li key={doc.documentName}> {doc.documentName} </li>
                                            ))}

                                        </ul>
                                        <span>Medical Rebate </span>
                                        <ul>
                                            <li>Medical Insurance card (both front and back)</li>
                                            <li>Explanation of Benefits form from primary insurance (EOB)</li>
                                            <span>OR</span>
                                            <li>Provider statement showing patient's responsible charges</li>
                                        </ul>

                                    </h5>
                                    }
                                    <h6>Files must be in one of the following formats - <strong>doc, docx, pdf, jpg,
                                        jpeg, tif, tiff</strong>. Maximum file size limit is <strong>5 MB</strong>.
                                    </h6>

                                    <div className="clear">
                                        <React.Fragment>
                                            <Grid container item spacing={3}>
                                                {fileList.map((value) => (
                                                    <Grid item key={value.id} xs={12} sm={4}>
                                                        <div>
                                                            <Paper className={classes.paper} elevation={0}>
                                                                {value.imagePreviewUrl !== '' &&
                                                                <img src={value.src} className={classes.image}
                                                                     alt="..."/>
                                                                }
                                                            </Paper>
                                                            <input accept="image/*, .doc, .docx,.pdf"
                                                                   onChange={(e) => addfile(props, value.id, e)}
                                                                   className={classes.input} id={value.uploadinput}
                                                                   type="file"/>
                                                            <label htmlFor={value.uploadinput}>
                                                                <IconButton color="primary"
                                                                            aria-label={value.uploadinput}
                                                                            component="span">
                                                                    <OpenInBrowserIcon/>
                                                                </IconButton>
                                                            </label>
                                                            <IconButton color="primary" aria-label="delete picture"
                                                                        component="span"
                                                                        onClick={(e) => deletefile(props, value.id, e)}>
                                                                <DeleteIcon/>
                                                            </IconButton>

                                                            <br></br><span
                                                            className={classes.span}> {trimFileName(value.id)} </span>
                                                        </div>
                                                    </Grid>
                                                ))}
                                                {addchck &&
                                                <Grid item>
                                                    {maxFileListChk &&
                                                    <>
                                                        <Paper className={classes.paper} elevation={0}>
                                                            <br></br>
                                                            <br></br>
                                                            <span className={classes.span1}>Browse</span>
                                                        </Paper>

                                                        {eventFix &&
                                                        <>
                                                            <input accept="image/*,.doc, .docx,.pdf" onChange={(e) => {
                                                                addfile(props, "newfile", e)
                                                            }} className={classes.input} id="icon-button-file-first"
                                                                   type="file"/>
                                                            <label htmlFor="icon-button-file-first">
                                                                <IconButton color="primary" aria-label="upload picture"
                                                                            component="span">
                                                                    <OpenInBrowserIcon/>
                                                                </IconButton>
                                                            </label>
                                                        </>
                                                        }
                                                        {!eventFix &&
                                                        <>
                                                            <input accept="image/*,.doc, .docx,.pdf" onChange={(e) => {
                                                                addfile(props, "newfile", e)
                                                            }} className={classes.input} id="icon-button-file-first1"
                                                                   type="file"/>
                                                            <label htmlFor="icon-button-file-first1">
                                                                <IconButton color="primary" aria-label="upload picture"
                                                                            component="span">
                                                                    <OpenInBrowserIcon/>
                                                                </IconButton>
                                                            </label>
                                                        </>
                                                        }

                                                    </>
                                                    }
                                                    <br></br>
                                                    {(fileselectchk || (!props.values.fileselect && props.errors.fileselect)) &&
                                                    <span
                                                        style={{color: "red"}}>At least one document is required <br></br></span>}
                                                    {duplicatefile && <span style={{color: "red"}}>Duplicate documents are not allowed</span>}

                                                    {maxFileSizeChk && <span style={{color: "red"}}>Document size should not exceed 5MB</span>}

                                                    {fileFormatChk &&
                                                    <span style={{color: "red"}}>Files must be in one of the above formats</span>}


                                                </Grid>
                                                }


                                            </Grid>
                                        </React.Fragment>
                                    </div>
                                </div>

                                <div className="flow-option-block">
                                    <h5>Certification Statement</h5><br></br><br></br>
                                    <p>
                                        By signing the below, I certify that I have read and agree to the Terms and
                                        Conditions of my coupon.
                                        <br></br><br></br>
                                        I certify that I personally paid or have responsibility to pay the co-pay
                                        required by my prescription/medical insurer
                                        for the product for which I am seeking reimbursement (or I have made a full cash
                                        payment if I am privately insured but my insurer does not provide coverage for
                                        the product).
                                        <br></br><br></br>
                                        I certify that the information provided in this request is accurate, that
                                        expenses requested for payment were not and will not be paid by any other
                                        source, including but not limited to my insurance, my Flexible Spending Account
                                        (FSA), or Health Savings Account (HSA), or any other type of medical savings
                                        account. I am not covered by any state or federally funded programs, including
                                        but not limited to Medicare, Medicaid, Medigap, VA, DOD, TriCare or where
                                        otherwise prohibited by federal or state law.
                                        <br></br><br></br>
                                        I certify that I am a resident of United States, Puerto Rico, Guam or Virgin
                                        Island.
                                        <br></br><br></br>
                                        I understand that the information provided here will be available to
                                        RxCrossroads by McKesson Corporation ("McKesson"), the administrator of this
                                        program, and to others working on behalf of McKesson, whose access is necessary
                                        for data processing, eligibility verification, and follow-up related to this
                                        program. McKesson may also provide this information as allowed by law to the
                                        manufacturer sponsoring the copay program.
                                        <br></br><br></br>
                                        By signing below, I agree that McKesson, and others working on behalf of
                                        McKesson, are authorized to verify this information and use it to verify my
                                        compliance with the Terms and Conditions of this program and to conduct fraud
                                        prevention processes.
                                        <br></br><br></br>
                                        I further acknowledge that all information including attachments submitted are
                                        complete and accurate.
                                        <br></br><br></br>
                                    </p>
                                    <div className="clear">
                                        <div className="m-block fl two-col-spaced   ">
                                            <MyTextInput label="* Authorized Signature" name="signature"
                                                         readOnlyFlag={selfchk}/>

                                        </div>
                                        <div className="m-block fl two-col-spaced ">
                                            <div className="m-block fl two-sm-col-spaced">
                                                <RelationSelect InputLabelProps={{shrink: true,}}
                                                                label="* Relationship to Patient" name="relationship"
                                                                parentprops={props} relationlookup={updateRelation}
                                                                patientname={patientname}/>
                                                <br></br></div>
                                            <div className="m-block fl two-sm-col-spaced-r  ">
                                                <SignatureDate label="* Date" name="signdate" readOnlyFlag={true}
                                                               errmsg="Date is required"/>
                                                <br></br></div>
                                        </div>
                                    </div>

                                    <div>

                                        Font  &nbsp;&nbsp;
                                        {!props.values.fontselect && props.errors.fontselect &&
                                        <span style={{color: "red"}}>{props.errors.fontselect}</span>}
                                        <br></br>
                                        <Radio
                                            checked={font1}
                                            value="font1"
                                            name="radiofont1"
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setFont1(true);
                                                    setFont2(false);
                                                    setFont3(false);
                                                    props.setFieldValue("fontValue", "font1", false);
                                                    props.setFieldValue("fontselect", true, false);


                                                }

                                            }}
                                        />

                                        {props.values.signature === "" ?
                                            <span className="attest_font1"> Authorized Signature  </span> :
                                            <span className="attest_font1">{props.values.signature} </span>}
                                        <br></br>
                                        <Radio
                                            checked={font2}
                                            value="font2"
                                            name="radiofont2"
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setFont1(false);
                                                    setFont2(true);
                                                    setFont3(false);
                                                    props.setFieldValue("fontValue", "font2", false);
                                                    props.setFieldValue("fontselect", true, false);

                                                }

                                            }}
                                        />
                                        {props.values.signature === "" ?
                                            <span className="attest_font2"> Authorized Signature  </span> :
                                            <span className="attest_font2">{props.values.signature} </span>}
                                        <br></br>
                                        <Radio
                                            checked={font3}
                                            value="font3"
                                            name="radiofont3"
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setFont1(false);
                                                    setFont2(false);
                                                    setFont3(true);
                                                    props.setFieldValue("fontValue", "font3", false);
                                                    props.setFieldValue("fontselect", true, false);
                                                }

                                            }}
                                        />
                                        {props.values.signature === "" ?
                                            <span className="attest_font3"> Authorized Signature  </span> :
                                            <span className="attest_font3">{props.values.signature} </span>}
                                    </div>

                                </div>

                                <div className="m-block block-center   ">
                                    <div className="m-block">
                                        <Button type="submit" name="onlinesubmitbtn"><span
                                            className="button btn-primary">PREVIEW</span></Button>
                                    </div>
                                </div>
                                <br></br>


                                <div className="m-block block-center   ">
                                    <div className="m-block">
                                        <Button onClick={() => nextpage(`/web/PatientCertification`)} name="back"><span
                                            className="button btn-secondary">BACK</span></Button>
                                    </div>
                                </div>
                                <br></br><br></br><br></br><br></br><br></br>

                            </div>


                        </div>
                    </div>
                </form>

            )}
        </Formik>
    );

}

export default UploadAndAttest