import React from 'react';
import {useField} from 'formik';
import TextField from '@material-ui/core/TextField';

const chkReadOnly = (val) => {
    let chk = false;
    if (val === true) {
        chk = true;
    }
    return chk;
}

const CertTextInput = ({label, labelprops, readOnlyFlag, inputProps, ...props}) => {
    const [field, meta] = useField(props);
    const inputprops =  inputProps || {};

    return (
        <TextField {...field} {...props}
                   inputProps={{readOnly: chkReadOnly(readOnlyFlag), ...inputprops}}
                   InputLabelProps={labelprops}
                   label={label}
                   helperText={meta.error ? meta.error : ""}
                   error={meta.error ? true : false}
        />
    );
};


export default CertTextInput