import {SAVE_PATIENT_INFO} from "../action/PatientLokUpAction";


const PatientLookUpReducer = (state = [], action) => {
  let newstate = [];
  if (action.type === SAVE_PATIENT_INFO) {
    newstate = action.payload;
  }

  return newstate;
};


export default PatientLookUpReducer;

