import React from 'react';
import ReactDOM from 'react-dom';
import RootApp from './component/app/RootApp';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


import './index.css';

ReactDOM.render(
    <RootApp/>,
    document.getElementById('root')
);

