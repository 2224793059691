import React from 'react';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";


const PatientPrescriberPage = () => {

    let history = useHistory();

    return (
        <div className="main-content-wrapper">
            <div className="nine-tenth-mobile block-center">
                <div className="clear">
                    <div className="m-block fl two-col padding-r-lg">
                        <h1> Welcome to RxCrossroads <br></br> by McKesson's Copay Self Service Portal! </h1>
                        <h4><br></br>
                            RxCrossroads is pleased to provide you with fast, reliable assistance in obtaining a rebate
                            for the discount amount you paid when you presented your co-pay card.
                            <br></br> <br></br>To help identify you in our system, please provide the following
                            information.
                            This information will ONLY be used to validate your eligibility.
                        </h4>  <br></br>
                        <div className="m-block block-center  padding-r-xs">
                            <div className="block-center">
                                <Button variant="contained" name="gotoHomeButton" id="card-btn"
                                        onClick={() => history.push('/web/Home')}><span
                                    className="button btn-primaryTwo"> I'm a patient </span></Button>
                            </div>
                            <br></br>
                            <div className="block-center">
                                <Button variant="contained" name="prescriberButton" id="no-card-btn"><span
                                    className="button btn-primaryThree"
                                    onClick={() => window.open("http://www.google.com", "_blank")}>I am a prescriber</span></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PatientPrescriberPage;