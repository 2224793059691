import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {Formik} from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import StepsImage from '../common/StepsImage';
import Grid from '@material-ui/core/Grid';
import * as yup from "yup";
import {SAVE_PATIENT_INFO} from "../../action/PatientLokUpAction";
import MyTextInput from "../common/MyTextInput";
import StateSelect from "../common/StateSelect";
import ProgramIDSelect from "../common/ProgramIDSelect";
import {SimpleTable1, SimpleTable2} from "../common/CardBenefitTable";
import {setEnvVariables} from "../../config/EnvUtil";
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CertTextInput from "../common/CertTextInput";
import {encryptFunction} from '../common/Util';
import ReceiptDateUtils from "../common/ReceiptDateUtils";
import Dialog from "@material-ui/core/Dialog";
import DOMPurify from "isomorphic-dompurify";
import parse from "html-react-parser";


const htmlFrom = (htmlString) => {

    const cleanHtmlString = DOMPurify.sanitize(htmlString,
        { USE_PROFILES: { html: true } });
    const html = parse(cleanHtmlString);
    return html;
}




const checkNull = (element) => {
    if (element != null) {
        return element;
    } else {
        return ""
    }
}

const dateFormat = (dob) => {
    var dd = dob.getDate();
    var mm = dob.getMonth() + 1;
    var yyyy = dob.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return (mm + '/' + dd + '/' + yyyy);
}


const receiptDateSet = (dateVal) => {
    if (dateVal !== null) {
        //alert(dateVal);
        return new Date(dateVal);
    } else {
        return null;
    }
}

const PatientCertification = () => {
    let endpoint = setEnvVariables(window.location.hostname);
    let history = useHistory();
    const patientInfo = useSelector(state => state.patientInfo);
    const [loadIcon, setLoadIcon] = useState(false);
    const [patientAddChk, setPatientAddChk] = useState(false);
    const [showFields, setShowFields] = useState(true);
    const dobErr = "This field is required in MM/DD/YYYY format and should not be future date.";
    const [serverErr, setServerErr] = useState(false);
    const [serverErrMsg, setServerErrMsg] = useState("");
    const [eventLookup, setEventLookup] = useState(patientInfo.eventLookup);
    const [errorChk, setErrorChk] = useState(false);
    const [newUploadFlow, setNewUploadFlow] = useState(false);
    const [nextPageClick, setNextPageClick] = useState(false);
    const [boxChecked, setBoxChecked] = useState(false);

    const [successChk, setSuccessChk] = useState(false);
    const [printResponse, setPrintResponse] = useState("");
    const [printFileName, setPrintFileName] = useState("");
    const [addrErr, setAddrErr] = useState(false);
    const [addrErrMsg, setAddrErrMsg] = useState("");
    const [eventLookupClinetInfo, setEventLookupClinetInfo] = useState("");
    const [startDateNameLookup, setStartDateNameLookup] = useState("");
    const [endDateNameLookup, setEndDateNameLookup] = useState("");
    const [cardStatusNameLookup, setCardStatusNameLookup] = useState("");
    const [prognameNameLookup, setPrognameNameLookup] = useState("");

    const location = useLocation();

    if(location.search && location.search.includes("back")){
        document.getElementsByName("confirmMailAdress").checked = false;
    }
    let submitChk = true;

    const setMaxQtyMessage = (x) => {
        let medicationquantitymsg = 'This field is required upto 4 digits with decimal at any point.';
        if (x !== 0 && x <= 10000) {
            medicationquantitymsg = 'This field should be less than or equal to ' + x + '.';
        }
        return medicationquantitymsg;
    }

    const setMaxDaysSuppliedMessage = (y) => {
        let medicationdaysMsg = 'This field must be less than or equal to 360 days.';
        if (y !== 0 && y < 361) {
            medicationdaysMsg = 'This field should be less than or equal to ' + y + '.';
        }
        return medicationdaysMsg;
    }

    const populateMaxQty = (qty) => {
        if (qty === 0 || qty > 10000) {
            qty = 10000;
        } else {
            qty += 1;
        }
        return qty;
    }

    const populateMaxDaysSupplied = (days) => {
        if (days === 0 || days > 360) {
            days = 361;
        } else {
            days += 1;
        }
        return days;
    }

    function setValuesforNameLookup(row){
        setEventLookupClinetInfo(row.clientInfo)
        setStartDateNameLookup(row.startDate)
        setEndDateNameLookup(row.endDate)
        setCardStatusNameLookup(row.cardStatus)
        setPrognameNameLookup(row.programName)
    }

    const [maxQtyMsg, setMaxQtyMsg] = useState(setMaxQtyMessage(patientInfo.maxQty));
    const [maxQty, setMaxQty] = useState(populateMaxQty(patientInfo.maxQty));
    const [maxDaysSuppliedMsg, setMaxDaysSuppliedMsg] = useState(setMaxDaysSuppliedMessage(patientInfo.maxDaysSupplied));
    const [maxDaysSupplied, setMaxDaysSupplied] = useState(populateMaxDaysSupplied(patientInfo.maxDaysSupplied));
    const [activeDate, setActiveDate] = useState(new Date(patientInfo.activeDate));
    const [endDated, setEndDated] = useState(new Date(patientInfo.endDated));
    const [eventLookupCardId, setEventLookupCardId] =  useState("");
    const [eventLookupProgramDataList, setEventLookupProgramDataList] =  useState({});

   

    //console.log("eventLookupProgramDataList" + eventLookupProgramDataList);
    //console.log(patientInfo);

    const setMessages = (maxQty, maxDays, activeDate, cardId, progData, endDated) => {
        if (maxQty != null) {
            setMaxQtyMsg(setMaxQtyMessage(maxQty));
            setMaxQty(populateMaxQty(maxQty));
        }

        if (maxDays != null) {
            setMaxDaysSuppliedMsg(setMaxDaysSuppliedMessage(maxDays));
            setMaxDaysSupplied(populateMaxDaysSupplied(maxDays));
        }
        if (cardId != null) {
            setEventLookupCardId(progData.cardId);
            setEventLookupProgramDataList(progData);
            setValuesforNameLookup(progData);
        }
        if (activeDate != null) {
            setActiveDate(new Date(activeDate.trim()));
        }

        if (endDated != null) {
            setEndDated(new Date(endDated.trim()));
        }
    }


    const dispatch = useDispatch();
    var validationSchema;

    const [open, setOpen] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(true);
    const [printLoadIcon, setPrintLoadIcon] = useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const updateEventLookup = (x) => setEventLookup(x);
    //   const submitAnother = () => history.push('/web');
    const submitAnother = () => window.location.href = "/web/";

    const downloadFile = () => {
        const url = window.URL.createObjectURL(new Blob([printResponse]));
        const link = document.createElement('a');
        link.href = DOMPurify.sanitize(url);
        let name = generateFileName(printFileName);
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const generateFileName = (name) => {
        if (name.includes("gilead_dmr_form")) {
            return "gilead_dmr_form.pdf";
        } else if (name.includes("novartis_dmr_form")) {
            return "novartis_dmr_form.pdf";
        } else if (name.includes("kowa_dmr_form")) {
            return "kowa_dmr_form.pdf";
        } else if (name.includes("merck_dmr_0026_form")) {
            return "merck_dmr_0026_form.pdf";
        } else if (name.includes("merck_dmr_0027_form")) {
            return "merck_dmr_0027_form.pdf";
        } else {
            return name;
        }
    }

    if (showFields || patientInfo.flowName === 'UPLOAD_RECEIPT' || (eventLookupProgramDataList && eventLookupProgramDataList.flowName !== '' && eventLookupProgramDataList.flowName === "UPLOAD_RECEIPT")) {
        validationSchema = yup.object().shape({
            programNameSel: yup.string()
                .required('This field is required.')
                .min(1, 'This field is required.'),
            patientadd1: yup.string()
                .required('This field is required.')
                .max(40, 'This field should not exceed 40 characters.')
                .min(1, 'This field is required.'),
            patientadd2: yup.string()
                .max(40, 'This field should not exceed 40 characters.'),
            patientcity: yup.string()
                .required('This field is required.')
                .max(40, 'This field should not exceed 40 characters.')
                .min(1, 'This field is required.'),
            patientphone: yup.string()
                .required('This field must be 10 digits long.')
                .matches(/^[0-9]+$/, 'This field must be 10 digits long.')
                .min(10, 'This field must be 10 digits long.')
                .max(10, 'This field must be 10 digits long.'),
            patientzip: yup.string()
                .required('This field must be 5 digits long.')
                .matches(/^[0-9]+$/, 'This field must be 5 digits long.')
                .min(5, 'This field must be 5 digits long.')
                .max(5, 'This field must be 5 digits long.'),
            // patientemail: yup.string()
            //     .required('Valid Email Address is required.')
            //     .email('Valid Email Address is required.'),
            patientemail: yup.string().when('patientzip', (email, validationSchema) => {
                // alert(patientInfo.orgProgCamId);
                if(patientInfo.type === "card" ){
                    if (patientInfo.programList[0].customerOrgId === 57 && patientInfo.orgProgCamId === 7544) {
                        return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                    } else if(patientInfo.programList[0].customerOrgId === 57) {
                        return validationSchema.email('Valid Email Address is required.').nullable();
                    }
                    return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                }else{
                    if (patientInfo.programList[0].customerOrgId === 57 && patientInfo.orgProgCamId === 7544) {
                        return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                    } else if(patientInfo.programList[0].customerOrgId === 57) {
                        return validationSchema.email('Valid Email Address is required.').nullable();
                    }
                    return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                }
            }),
            //   patientemail: yup.string().when('patientzip', (patientInfo, email, schema) => {
            //     console.log(patientInfo);
            //     //email('Valid Email Address is required.')
            //     //if (patientInfo.programList[0].customerOrgId === 57) { return schema.email('Valid Email Address is required.'); }
            //     return schema.required('Valid Email Address is required.');
            //   }),

        });
    } else {
        validationSchema = yup.object().shape({
            programNameSel: yup.string()
                .required('This field is required.')
                .min(1, 'This field is required.'),
            patientadd1: yup.string()
                .required('This field is required.')
                .max(40, 'This field should not exceed 40 characters.')
                .min(1, 'This field is required.'),
            patientadd2: yup.string()
                .max(40, 'This field should not exceed 40 characters.'),
            patientcity: yup.string()
                .required('This field is required.')
                .max(40, 'This field should not exceed 40 characters.')
                .min(1, 'This field is required.'),
            patientphone: yup.string()
                .required('This field must be 10 digits long.')
                .matches(/^[0-9]+$/, 'This field must be 10 digits long.')
                .min(10, 'This field must be 10 digits long.')
                .max(10, 'This field must be 10 digits long.'),
            patientzip: yup.string()
                .required('This field must be 5 digits long.')
                .matches(/^[0-9]+$/, 'This field must be 5 digits long.')
                .min(5, 'This field must be 5 digits long.')
                .max(5, 'This field must be 5 digits long.'),
            // patientemail: yup.string()
            //     .required('Valid Email Address is required.')
            //     .email('Valid Email Address is required.'),
            patientemail: yup.string().when('patientzip', (email, validationSchema) => {
                if(patientInfo.type === "card" ){
                    if (patientInfo.programList[0].customerOrgId === 57 && patientInfo.orgProgCamId === 7544) {
                        return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                    } else if(patientInfo.programList[0].customerOrgId === 57) {
                        return validationSchema.email('Valid Email Address is required.').nullable();
                    }
                    return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                }else{
                    if (patientInfo.programList[0].customerOrgId === 57 && patientInfo.orgProgCamId === 7544) {
                        return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                    } else if(patientInfo.programList[0].customerOrgId === 57) {
                        return validationSchema.email('Valid Email Address is required.').nullable();
                    }
                    return validationSchema.email('Valid Email Address is required.').required('Valid Email Address is required.').nullable();
                }
            }),
            pharmacyname: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required.')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
                }),
            pharmacyaddress1: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required. ')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
                }),
            pharmacyaddress2: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .max(40, 'This field should not exceed 40 characters.')
                }),
            pharmacycity: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required.')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
                }),
            pharmacyzip: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field must be 5 digits long.')
                        .matches(/^[0-9]+$/, 'This field must be 5 digits long.')
                        .min(5, 'This field must be 5 digits long.')
                        .max(5, 'This field must be 5 digits long.'),
                }),

            pharmacystate: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required.')
                        .min(1, 'This field is required.'),
                }),
            medicationname: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required.')
                        .min(1, 'This field is required.'),
                    // .max(250, 'Must be 250 characters or less'),
                }),
            prescriptionnum: yup.string()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.string()
                        .required('This field is required in digits.')
                        .matches(/^([0-9]|MB)+$/, 'This field is required in digits.')
                        .min(1, 'This field is required in digits.')
                }),
            medicationquantity: yup.number()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.number()
                        .typeError('This field is required upto 4 digits with decimal at any point.')
                        .required('This field is required upto 4 digits with decimal at any point.')
                        .moreThan(0, 'This field must be greater than 0.')
                        .lessThan(maxQty, maxQtyMsg)
                }),
            medicationdays:
                yup.string()
                    .when('confirmMailAdress', {
                        is: true,
                        then: yup.string()
                            .required('This field is required in digits.')
                            .matches(/^([0-9]|MB)+$/, 'This field is required in digits.')
                            .matches(/^([0-9]|[1-8][0-9]|90|MB)$/, maxDaysSuppliedMsg)
                            .max(2, maxDaysSuppliedMsg)
                    }),
            amountpaid: yup.number()
                .when('confirmMailAdress', {
                    is: true,
                    then: yup.number()
                        .typeError('This field is required in decimal.')
                        .required('This field is required in decimal.')
                        .moreThan(0, 'This field is required in decimal.')
                }),
            reciptdate: yup.date()
                .required("Receipt Date is required in MM/DD/YYYY format and should not be future date.")
        });
    }

    var formSchema = {
        programNameSel: checkNull(patientInfo.orgProgCamId),
        onlineUpload: checkNull(patientInfo.onlineUpload),
        patientadd1: checkNull(patientInfo.address.addressLine1),
        patientadd2: checkNull(patientInfo.address.addressLine2),
        patientcity: checkNull(patientInfo.address.city),
        patientzip: checkNull(patientInfo.address.zip),
        patientemail: checkNull(patientInfo.email),
        patientphone: checkNull(patientInfo.phoneNumber),
        patientstate: checkNull(patientInfo.address.state),
        confirmMailAdress: true,
        pharmacyname: patientInfo.pharmacyname,
        pharmacyaddress1: patientInfo.pharmacyaddress1,
        pharmacycity: patientInfo.pharmacycity,
        pharmacyaddress2: patientInfo.pharmacyaddress2,
        pharmacystate: patientInfo.pharmacystate,
        pharmacyzip: patientInfo.pharmacyzip,
        medicationname: patientInfo.medicationname,
        medicationquantity: patientInfo.medicationquantity,
        medicationdays: patientInfo.medicationdays,
        prescriptionnum: patientInfo.prescriptionnum,
        acceptedTermsChk: false,
        amountpaid: patientInfo.amountpaid,
        reciptdate: receiptDateSet(patientInfo.reciptdate),
        opcEventId: checkNull(patientInfo.opcEventId),
        cardId: patientInfo.cardId,
        eventLookupClinetInfo : patientInfo.eventLookupClinetInfo,
        startDateNameLookup: patientInfo.startDateNameLookup,
        endDateNameLookup: patientInfo.endDateNameLookup,
        cardStatusNameLookup : patientInfo.cardStatusNameLookup,
        prognameNameLookup: patientInfo.prognameNameLookup,
        eventLookupProgramDataList: patientInfo.eventLookupProgramDataList,
        eventLookupCardId: patientInfo.eventLookupCardId,

        cardStatus: patientInfo.cardStatus,
        startDate: patientInfo.startDate,
        endDate: patientInfo.endDate


    };

    React.useEffect(() => {
        if(!eventLookupClinetInfo && patientInfo.eventLookupClinetInfo) {
            setEventLookupClinetInfo(patientInfo.eventLookupClinetInfo)
        }
        if(!startDateNameLookup && patientInfo.startDateNameLookup) {
            setStartDateNameLookup(patientInfo.startDateNameLookup)
        }
        if(!endDateNameLookup && patientInfo.endDateNameLookup) {
            setEndDateNameLookup(patientInfo.endDateNameLookup)
        }
        if(!cardStatusNameLookup && patientInfo.cardStatusNameLookup) {
            setCardStatusNameLookup(patientInfo.cardStatusNameLookup)
        }
        if(!prognameNameLookup && patientInfo.prognameNameLookup) {
            setPrognameNameLookup(patientInfo.prognameNameLookup)
        }
        if(!eventLookupProgramDataList.flowName && patientInfo.eventLookupProgramDataList) {
            setEventLookupProgramDataList(patientInfo.eventLookupProgramDataList)
        }
        if(!eventLookupCardId && patientInfo.eventLookupCardId) {
            setEventLookupCardId(patientInfo.eventLookupCardId)
        }
    } , [patientInfo.eventLookupClinetInfo, patientInfo.startDateNameLookup, patientInfo.endDateNameLookup, patientInfo.cardStatusNameLookup, patientInfo.prognameNameLookup])

    const nextpage = (url) => {
        history.push(url)
    }

    return (

        <Formik
            initialValues={formSchema}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                console.log("Inside OnSubmit Function");
                console.log(patientInfo.address.hygieneCode);
               
                const axios = require('axios');
                if(!patientAddChk && ((patientInfo.address.addressLine1 ? (patientInfo.address.addressLine1 == values.patientadd1) : (values.patientadd1 == ""))
                    && (patientInfo.address.addressLine2 ? (patientInfo.address.addressLine2 == values.patientadd2) : (values.patientadd2 == ""))
                    && (patientInfo.address.city ? (patientInfo.address.city == values.patientcity) : (values.patientcity == ""))
                    && (patientInfo.address.zip ? (patientInfo.address.zip == values.patientzip) : (values.patientzip == ""))
                    && (patientInfo.address.state ? (patientInfo.address.state == values.patientstate) : (values.patientstate == "")))
                    &&
                    patientInfo.address.hygieneCode == "CLEANSED"
                ) {

                    console.log(" No changes in the address , hence skipping the service call. ");

                    let newAddress = {
                        "addressLine1": checkNull(patientInfo.address.addressLine1),
                        "addressLine2": checkNull(patientInfo.address.addressLine2),
                        "city": checkNull(patientInfo.address.city),
                        "state": checkNull(patientInfo.address.state),
                        "zip": checkNull(patientInfo.address.zip),
                        "hygieneCode": patientInfo.address.hygieneCode,
                        // "zipExtn": checkNull(response.data.zipExtn)
                    }
                    dispatch({
                        type: SAVE_PATIENT_INFO, payload: {
                            "orgProgCamId": values.programNameSel,
                            "onlineUpload": values.onlineUpload,
                            "address": newAddress,
                            "recipientId": patientInfo.recipientId,
                            "email": values.patientemail,
                            "phoneNumber": values.patientphone,
                            "programList": patientInfo.programList,
                            "type": patientInfo.type,
                            "firstName": patientInfo.firstName,
                            "lastName": patientInfo.lastName,
                            "dob": patientInfo.dob,
                            "pharmacyname": patientInfo.pharmacyname,
                            "pharmacyaddress1": patientInfo.pharmacyaddress1,
                            "pharmacyaddress2": patientInfo.pharmacyaddress2,
                            "pharmacycity": patientInfo.pharmacycity,
                            "pharmacystate": patientInfo.pharmacystate,
                            "pharmacyzip": patientInfo.pharmacyzip,
                            "medicationname": patientInfo.medicationname,
                            "medicationquantity": patientInfo.medicationquantity,
                            "medicationdays": patientInfo.medicationdays,
                            "prescriptionnum": patientInfo.prescriptionnum,
                            "amountpaid": patientInfo.amountpaid,
                            "reciptdate": patientInfo.reciptdate,
                            "eventLookup": eventLookup,
                            "opcEventId": values.opcEventId,
                            "cardId": patientInfo.cardId,
                            "maxQty": maxQty,
                            "maxDaysSupplied": maxDaysSupplied,
                            "clientInfo": patientInfo.clientInfo,
                            "prognameNameLookup":prognameNameLookup,
                            "flowName": patientInfo.flowName,
                            "activeDate": activeDate,
                            "endDated": endDated,
                            "startDateNameLookup": startDateNameLookup,

                            "cardStatus": patientInfo.cardStatus,
                            "startDate": patientInfo.startDate,
                            "endDate": patientInfo.endDate

                        }
                    });
                    setAddrErr(false);
                    setPatientAddChk(true);
                    setShowFields(false);

                } else if (!patientAddChk) {
                    console.log('patientAddChk not checked');
                    //Address Cleansing
                    setLoadIcon(true);
                    let token = encryptFunction(endpoint);
                    const headers = {
                        'X-Copay-API-Authorization': token.data,
                        'X-Copay-API-IV': token.token
                    };
                    axios({
                        method: "POST",
                        url: endpoint.validate,
                        headers: headers,
                        data: {
                            "firstName": patientInfo.firstName,
                            "lastName": patientInfo.lastName,
                            "dob": patientInfo.dob,
                            "addressLine1": values.patientadd1,
                            "addressLine2": values.patientadd2,
                            "city": values.patientcity,
                            "state": values.patientstate,
                            "zip": values.patientzip,
                            "phoneNumber": values.patientphone,
                            "email": values.patientemail
                        }
                    })
                        .then(response => {
                            //create object for address
                            if (response.status === 200) {
                                let newAddress = {
                                    "addressLine1": checkNull(response.data.addressLine1),
                                    "addressLine2": checkNull(response.data.addressLine2),
                                    "city": checkNull(response.data.city),
                                    "state": checkNull(response.data.state),
                                    "zip": checkNull(response.data.zip),
                                    "zipExtn": checkNull(response.data.zipExtn),
                                    "hygieneCode": checkNull(response.data.hygieneCode),
                                }
                                dispatch({
                                    type: SAVE_PATIENT_INFO, payload: {
                                        "orgProgCamId": values.programNameSel,
                                        "onlineUpload": values.onlineUpload,
                                        "address": newAddress,
                                        "recipientId": patientInfo.recipientId,
                                        "email": values.patientemail,
                                        "phoneNumber": values.patientphone,
                                        "programList": patientInfo.programList,
                                        "type": patientInfo.type,
                                        "firstName": patientInfo.firstName,
                                        "lastName": patientInfo.lastName,
                                        "dob": patientInfo.dob,
                                        "pharmacyname": patientInfo.pharmacyname,
                                        "pharmacyaddress1": patientInfo.pharmacyaddress1,
                                        "pharmacyaddress2": patientInfo.pharmacyaddress2,
                                        "pharmacycity": patientInfo.pharmacycity,
                                        "pharmacystate": patientInfo.pharmacystate,
                                        "pharmacyzip": patientInfo.pharmacyzip,
                                        "medicationname": patientInfo.medicationname,
                                        "medicationquantity": patientInfo.medicationquantity,
                                        "medicationdays": patientInfo.medicationdays,
                                        "prescriptionnum": patientInfo.prescriptionnum,
                                        "amountpaid": patientInfo.amountpaid,
                                        "reciptdate": patientInfo.reciptdate,
                                        "eventLookup": eventLookup,
                                        "opcEventId": values.opcEventId,
                                        "cardId": patientInfo.cardId,
                                        "maxQty": maxQty,
                                        "maxDaysSupplied": maxDaysSupplied,
                                        "clientInfo": patientInfo.clientInfo,
                                        "prognameNameLookup":prognameNameLookup,
                                        "flowName": patientInfo.flowName,
                                        "activeDate": activeDate,
                                        "endDated": endDated,

                                        "cardStatus": patientInfo.cardStatus,
                                        "startDate": patientInfo.startDate,
                                        "endDate": patientInfo.endDate
            
                                    }
                                });
                                setAddrErr(false);
                                setPatientAddChk(true);
                                setShowFields(false);
                            } else if (response.status === 203) {
                                //Show Error Code
                                setAddrErr(true);
                                setAddrErrMsg(response.data.errors);

                            }
                            actions.setSubmitting(false);
                            setLoadIcon(false);
                        })
                        .catch(error => {
                            console.log('Error' +error.message);
                            setPatientAddChk(false);
                            setAddrErr(true);
                            actions.setSubmitting(false);
                            if (error.message === "Network Error") {
                                setAddrErrMsg("Network Error.");
                            } else {
                                setAddrErrMsg("Internal Server Error.");
                            }
                            setLoadIcon(false);
                        });
                } else {
                    console.log('patientAddChk checked');
                    let newDate = new Date();
                    console.log('receipt date check passed');
                    let docList, programName, orgProgCamId, orgProgCamRecipientId, customerOrgId, cardId;
                    let programList = patientInfo.programList;

                    programList.map(program => {
                        if (program.orgProgCamId === patientInfo.orgProgCamId) {
                            docList = program.documentList;
                            orgProgCamId = program.orgProgCamId;
                            orgProgCamRecipientId = program.orgProgCamRecipientId;
                            programName = program.programName;
                            customerOrgId = program.customerOrgId;
                            cardId = program.cardId;
                            // onlineUpload = program.onlineUpload;
                        }
                    });

                    if(patientAddChk){
                        console.log('patientAddChk checked');
                        if(patientInfo.flowName === 'UPLOAD_RECEIPT' ||  eventLookupProgramDataList.flowName === 'UPLOAD_RECEIPT'){
                            console.log('Upload Receipt');
                            let patientFName = patientInfo.firstName;
                            let patientLName = patientInfo.lastName;
                            let signatureName = patientFName.trim() + " " + patientLName.trim();

                            if(eventLookupProgramDataList.flowName === 'UPLOAD_RECEIPT'){
                                console.log('UPLOAD_RECEIPT - Event Lookup');
                                console.log('Upload Receipts Dispatch Flow ' + eventLookupProgramDataList.flowName);

                                dispatch({
                                    type: SAVE_PATIENT_INFO, payload: {
                                        "orgProgCamId": eventLookupProgramDataList.orgProgCamId,
                                        "orgProgCamRecipientId": eventLookupProgramDataList.orgProgCamRecipientId,
                                        //"orgProgCamRecipientId": patientInfo.uploadReceipts_OrgProgCamRecipientId,
                                        "recipientId": patientInfo.recipientId,
                                        "email": patientInfo.email,
                                        "programName": eventLookupProgramDataList.programName,
                                        "phoneNumber": patientInfo.phoneNumber,
                                        "programList": patientInfo.programList,
                                        "address": patientInfo.address,
                                        "type": patientInfo.type,
                                        "onlineUpload": eventLookupProgramDataList.onlineUpload,
                                        "firstName": patientInfo.firstName,
                                        "lastName": patientInfo.lastName,
                                        "dob": patientInfo.dob,
                                        "clientInfo": eventLookupProgramDataList.clientInfo,
                                        "prognameNameLookup":prognameNameLookup,
                                        "fileList": [],
                                        "signature": signatureName,
                                        "relationship": "Self",
                                        "fontValue": "",
                                        "font1": false,
                                        "font2": false,
                                        "font3": false,
                                        "fontselect": false,
                                        "fileselect": false,
                                        "signdate": new Date(),
                                        "maxFileListChk": true,
                                        "opcEventId": patientInfo.opcEventId,
                                        "eventLookup": patientInfo.eventLookup,
                                        "cardId": eventLookupProgramDataList.cardId,
                                        "maxQty": maxQty,
                                        "maxDaysSupplied": maxDaysSupplied,
                                        "activeDate": activeDate,
                                        "endDated": endDated,
                                        "flowName": eventLookupProgramDataList.flowName,
                                        "customerOrgId": customerOrgId,

                                        "eventLookupClinetInfo" : eventLookupClinetInfo,
                                        "startDateNameLookup": startDateNameLookup,
                                        "endDateNameLookup": endDateNameLookup,
                                        "cardStatusNameLookup" : cardStatusNameLookup,
                                        "prognameNameLookup" : prognameNameLookup,
                                        "eventLookupProgramDataList": eventLookupProgramDataList,
                                        "eventLookupCardId" : eventLookupCardId,

                                        "cardStatus": patientInfo.cardStatus,
                                        "startDate": patientInfo.startDate,
                                        "endDate": patientInfo.endDate
                                    }
                                });
                                history.push({
                                    pathname: '/web/UploadAndAttest',
                                    search: 'uploadDocs',
                                    state: { detail: 'some_value' }
                                });
                                window.location.href = "#root";

                            }else{
                                console.log('UPLOAD_RECEIPT - Card Lookup');
                                dispatch({
                                    type: SAVE_PATIENT_INFO, payload: {
                                        "orgProgCamId": patientInfo.orgProgCamId,
                                        "orgProgCamRecipientId": orgProgCamRecipientId,
                                        //"orgProgCamRecipientId": patientInfo.uploadReceipts_OrgProgCamRecipientId,
                                        "recipientId": patientInfo.recipientId,
                                        "email": patientInfo.email,
                                        "programName": programName,
                                        "phoneNumber": patientInfo.phoneNumber,
                                        "programList": patientInfo.programList,
                                        "address": patientInfo.address,
                                        "type": patientInfo.type,
                                        "onlineUpload": patientInfo.onlineUpload,
                                        "firstName": patientInfo.firstName,
                                        "lastName": patientInfo.lastName,
                                        "dob": patientInfo.dob,
                                        "clientInfo": patientInfo.clientInfo,
                                        "prognameNameLookup":prognameNameLookup,
                                        "fileList": [],
                                        "signature": signatureName,
                                        "relationship": "Self",
                                        "fontValue": "",
                                        "font1": false,
                                        "font2": false,
                                        "font3": false,
                                        "fontselect": false,
                                        "fileselect": false,
                                        "signdate": new Date(),
                                        "maxFileListChk": true,
                                        "opcEventId": patientInfo.opcEventId,
                                        "eventLookup": patientInfo.eventLookup,
                                        "cardId": patientInfo.cardId,
                                        "maxQty": maxQty,
                                        "maxDaysSupplied": maxDaysSupplied,
                                        "activeDate": activeDate,
                                        "endDated": endDated,
                                        "flowName": patientInfo.flowName,
                                        "customerOrgId": customerOrgId,

                                        "cardStatus": patientInfo.cardStatus,
                                        "startDate": patientInfo.startDate,
                                        "endDate": patientInfo.endDate
                                        
                                    }
                                });
                                history.push({
                                    pathname: '/web/UploadAndAttest',
                                    search: 'uploadDocs',
                                    state: { detail: 'some_value' }
                                });
                                window.location.href = "#root";

                            }


                            //(values.onlineUpload || patientInfo.onlineUpload) &&
                        }else  if (values.reciptdate >= activeDate && values.reciptdate <= newDate) {


                            if (patientInfo.flowName === 'ONLINE_SUBMISSION' || eventLookupProgramDataList.flowName === 'ONLINE_SUBMISSION' ) {
                                console.log('online upload');

                                let patientFName = patientInfo.firstName;
                                let patientLName = patientInfo.lastName;
                                let signatureName = patientFName.trim() + " " + patientLName.trim();

                                if(eventLookupProgramDataList.flowName === 'ONLINE_SUBMISSION'){
                                    console.log('ONLINE_SUBMISSION - Event Lookup');
                                    dispatch({
                                        type: SAVE_PATIENT_INFO, payload: {
                                            "orgProgCamId": eventLookupProgramDataList.orgProgCamId,
                                            "orgProgCamRecipientId": eventLookupProgramDataList.orgProgCamRecipientId,
                                            "recipientId": patientInfo.recipientId,
                                            "email": patientInfo.email,
                                            "phoneNumber": patientInfo.phoneNumber,
                                            "programList": patientInfo.programList,
                                            "docList": eventLookupProgramDataList.documentList,
                                            "address": patientInfo.address,
                                            "type": patientInfo.type,
                                            "programName": eventLookupProgramDataList.programName,
                                            "onlineUpload": eventLookupProgramDataList.onlineUpload,
                                            "firstName": patientInfo.firstName,
                                            "lastName": patientInfo.lastName,
                                            "dob": patientInfo.dob,
                                            "pharmacyname": values.pharmacyname,
                                            "pharmacyaddress1": values.pharmacyaddress1,
                                            "pharmacyaddress2": values.pharmacyaddress2,
                                            "pharmacycity": values.pharmacycity,
                                            "pharmacystate": values.pharmacystate,
                                            "pharmacyzip": values.pharmacyzip,
                                            "medicationname": values.medicationname,
                                            "medicationquantity": values.medicationquantity,
                                            "medicationdays": values.medicationdays,
                                            "prescriptionnum": values.prescriptionnum,
                                            "amountpaid": values.amountpaid,
                                            "reciptdate": dateFormat(values.reciptdate),
                                            "fileList": [],
                                            "signature": signatureName,
                                            "relationship": "Self",
                                            "fontValue": "",
                                            "font1": false,
                                            "font2": false,
                                            "font3": false,
                                            "fontselect": false,
                                            "fileselect": false,
                                            "signdate": new Date(),
                                            "maxFileListChk": true,
                                            "customerOrgId": eventLookupProgramDataList.customerOrgId,
                                            "opcEventId": values.opcEventId, // Handle in back button
                                            "eventLookup": patientInfo.eventLookup,
                                            "cardId": eventLookupProgramDataList.cardId,
                                            "maxQty": maxQty,
                                            "maxDaysSupplied": maxDaysSupplied,
                                            "activeDate": activeDate,
                                            "endDated": endDated,
                                            "flowName": eventLookupProgramDataList.flowName,
                                            "eventLookupClinetInfo" : eventLookupClinetInfo,
                                            "startDateNameLookup": startDateNameLookup,
                                            "endDateNameLookup": endDateNameLookup,
                                            "cardStatusNameLookup" : cardStatusNameLookup,
                                            "prognameNameLookup" : prognameNameLookup,
                                            "eventLookupProgramDataList": eventLookupProgramDataList,
                                            "eventLookupCardId" : eventLookupCardId
                                        }
                                    });
                                    history.push({
                                        pathname: '/web/UploadAndAttest',
                                        search: 'onlineSubmission',
                                        state: { detail: 'some_value' }
                                    });
                                    //nextpage(`/web/UploadAndAttest/rebateflow`);
                                    window.location.href = "#root";

                                }else{
                                    console.log('ONLINE_SUBMISSION - Card Lookup');
                                    dispatch({
                                        type: SAVE_PATIENT_INFO, payload: {
                                            "orgProgCamId": orgProgCamId,
                                            "orgProgCamRecipientId": orgProgCamRecipientId,
                                            "recipientId": patientInfo.recipientId,
                                            "email": patientInfo.email,
                                            "phoneNumber": patientInfo.phoneNumber,
                                            "programList": patientInfo.programList,
                                            "docList": docList,
                                            "address": patientInfo.address,
                                            "type": patientInfo.type,
                                            "programName": programName,
                                            "onlineUpload": patientInfo.onlineUpload,
                                            "firstName": patientInfo.firstName,
                                            "lastName": patientInfo.lastName,
                                            "dob": patientInfo.dob,
                                            "pharmacyname": values.pharmacyname,
                                            "pharmacyaddress1": values.pharmacyaddress1,
                                            "pharmacyaddress2": values.pharmacyaddress2,
                                            "pharmacycity": values.pharmacycity,
                                            "pharmacystate": values.pharmacystate,
                                            "pharmacyzip": values.pharmacyzip,
                                            "medicationname": values.medicationname,
                                            "medicationquantity": values.medicationquantity,
                                            "medicationdays": values.medicationdays,
                                            "prescriptionnum": values.prescriptionnum,
                                            "amountpaid": values.amountpaid,
                                            "reciptdate": dateFormat(values.reciptdate),
                                            "fileList": [],
                                            "signature": signatureName,
                                            "relationship": "Self",
                                            "fontValue": "",
                                            "font1": false,
                                            "font2": false,
                                            "font3": false,
                                            "fontselect": false,
                                            "fileselect": false,
                                            "signdate": new Date(),
                                            "maxFileListChk": true,
                                            "customerOrgId": customerOrgId,
                                            "opcEventId": values.opcEventId, // Handle in back button
                                            "eventLookup": patientInfo.eventLookup,
                                            "cardId": cardId,
                                            "maxQty": maxQty,
                                            "maxDaysSupplied": maxDaysSupplied,
                                            "activeDate": activeDate,
                                            "endDated": endDated,
                                            "flowName": patientInfo.flowName,

                                            "cardStatus": patientInfo.cardStatus,
                                            "startDate": patientInfo.startDate,
                                            "endDate": patientInfo.endDate


                                           
                                        }
                                    });
                                    history.push({
                                        pathname: '/web/UploadAndAttest',
                                        search: 'onlineSubmission',
                                        state: { detail: 'some_value' }
                                    });
                                    //nextpage(`/web/UploadAndAttest/rebateflow`);
                                    window.location.href = "#root";
                                }


                            } else{
                                if (patientInfo.flowName === 'PRINT_AND_SUBMIT' || eventLookupProgramDataList.flowName === 'PRINT_AND_SUBMIT') {
                                    console.log('PRINT_AND_SUBMIT');
                                    submitChk = false;
                                    setSubmitBtn(false);
                                    setPrintLoadIcon(true);
                                    setSuccessChk(false);
                                    setErrorChk(false);
                                    handleClickOpen();

                                    let patientInformation = {
                                        "firstName": patientInfo.firstName,
                                        "lastName": patientInfo.lastName,
                                        "dob": patientInfo.dob,
                                        "addressLine1": patientInfo.address.addressLine1,
                                        "addressLine2": patientInfo.address.addressLine2,
                                        "city": patientInfo.address.city,
                                        "state": patientInfo.address.state,
                                        "zip": patientInfo.address.zip,
                                        "phoneNumber": patientInfo.phoneNumber,
                                        "email": patientInfo.email
                                    }
                                    let pharmacyInfo = {
                                        "name": values.pharmacyname,
                                        "address1": values.pharmacyaddress1,
                                        "address2": values.pharmacyaddress2,
                                        "city": values.pharmacycity,
                                        "state": values.pharmacystate,
                                        "zip": values.pharmacyzip
                                    }
                                    let prescriptionInfo = {
                                        "name": values.medicationname,
                                        "quantity": values.medicationquantity,
                                        "daysSupplied": values.medicationdays,
                                        "number": values.prescriptionnum,
                                        "amount": values.amountpaid,
                                        "receiptDate": dateFormat(values.reciptdate)
                                    }

                                    let requestBody;

                                    if(eventLookupProgramDataList.flowName === 'PRINT_AND_SUBMIT'){
                                        console.log('PRINT_AND_SUBMIT - Event Look up');
                                        requestBody= {
                                            "orgProgCamRecipientId": eventLookupProgramDataList.orgProgCamRecipientId,
                                            "orgRecipientId": patientInfo.recipientId,
                                            "orgProgCamId": eventLookupProgramDataList.orgProgCamId,
                                            "onlineUpload": eventLookupProgramDataList.onlineUpload,
                                            "cardId": eventLookupProgramDataList.cardId,
                                            "opcEventId": values.opcEventId,
                                            "customerOrgId": eventLookupProgramDataList.customerOrgId,
                                            "patientInfo": patientInformation,
                                            "pharmacyInfo": pharmacyInfo,
                                            "prescriptionInfo": prescriptionInfo,
                                            "flowName": eventLookupProgramDataList.flowName
                                        }
                                    }else{
                                        console.log('PRINT_AND_SUBMIT - Card Look up');
                                        requestBody= {
                                            "orgProgCamRecipientId": orgProgCamRecipientId,
                                            "orgRecipientId": patientInfo.recipientId,
                                            "orgProgCamId": orgProgCamId,
                                            "onlineUpload": patientInfo.onlineUpload,
                                            "cardId": cardId,
                                            "opcEventId": values.opcEventId,
                                            "customerOrgId": customerOrgId,
                                            "patientInfo": patientInformation,
                                            "pharmacyInfo": pharmacyInfo,
                                            "prescriptionInfo": prescriptionInfo,
                                            "flowName": patientInfo.flowName
                                        }
                                    }
                                    let fileName;
                                    let token = encryptFunction(endpoint);
                                    const headers = {
                                        'X-Copay-API-Authorization': token.data,
                                        'X-Copay-API-IV': token.token
                                    };
                                    axios({
                                        method: "POST",
                                        url: endpoint.mail,
                                        headers: headers,
                                        data: requestBody,
                                        responseType: 'arraybuffer',
                                    })
                                        .then(response => {
                                            fileName = response.headers.pragma;
                                            if (response.status === 200) {
                                                setSuccessChk(true);
                                                setPrintResponse(response.data);
                                                setPrintFileName(fileName);
                                                axios({
                                                    method: "DELETE",
                                                    url: endpoint.deleteFile + fileName,
                                                    headers: headers,
                                                }).then(deleteResponse => {
                                                    // console.log("Response from Delete file service : " + deleteResponse.status)
                                                }).catch(error => {
                                                    console.log(error.message);
                                                });

                                                setPrintLoadIcon(false);

                                            } else if (response.status === 203) {
                                                setErrorChk(true);
                                                setSubmitBtn(true);
                                                setServerErr(true);
                                                const res = JSON.parse(Buffer.from(response.data).toString('utf8'));
                                                setServerErrMsg(res.errors.length === 0 ? "An error occurred while attempting to process your request." : res.errors);
                                                //handleClickOpen();
                                                setPrintLoadIcon(false);
                                            }

                                            submitChk = true;

                                        }).catch(error => {
                                        setSubmitBtn(true);
                                        setServerErr(true);
                                        setErrorChk(true);
                                        console.log(error);
                                        if (error.message === "Network Error") {
                                            setServerErrMsg("Network Error.");
                                        } else {
                                            setServerErrMsg("An error occurred while attempting to process your request.");
                                        }

                                        // handleClickOpen();
                                        setPrintLoadIcon(false);

                                        submitChk = true;

                                    });
                                }

                            }

                        }
                    }
                }
            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <div className="main-content-wrapper">
                        <div className="nine-tenth-mobile block-center">
                            <div className="m-block fl  padding-r-lg " style={{ width: '100%', }}>
                                <StepsImage></StepsImage>
                                <h1 className="modal-title"> PATIENT CERTIFICATION </h1>
                                <h4 ><br></br>

                                </h4>  <br></br>
                                <div className="flow-option-block">
                                    {/* {patientInfo.type === "card" ?
                                        <h5>Program Name: {patientInfo.programList[0].programName}</h5> :
                                        } */}
                                    <div className="clear">
                                        <div className="m-block  fl ">
                                            {
                                                patientInfo.type === "card" ?
                                                    <>
                                                        <h5>Program Name: {patientInfo.programList[0].programName}</h5>
                                                        {
                                                            patientInfo.programList[0].flowName !== "UPLOAD_RECEIPT" ?
                                                                <Grid item xs={12}>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <span>The following documents are required to complete the Patient Rebate Submission: </span>
                                                                    <br/>
                                                                    <span>Pharmacy Rebate </span>

                                                                    <ul>
                                                                        {patientInfo.programList[0].documentList.map(doc => (
                                                                            <li key={doc.documentName}> {doc.documentName} </li>
                                                                        ))}

                                                                    </ul>
                                                                    <span>Medical Rebate </span>
                                                                    <ul>
                                                                        <li>Medical Insurance card (both front and back)</li>
                                                                        <li>Explanation of Benefits form from primary insurance (EOB)</li>
                                                                        <span>OR</span>
                                                                        <li>Provider statement showing patient's responsible charges</li>
                                                                    </ul>
                                                                </Grid>

                                                                :

                                                                <>
                                                                </>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <ProgramIDSelect label="* Select a Program Name"
                                                                         name="programNameSel" parentprops={props}
                                                                         eventLookupFun={updateEventLookup}
                                                                         setMessagesFun={setMessages}
                                                                         readOnlyFlag={patientAddChk}/>

                                                        {eventLookup && eventLookupCardId !== '' && eventLookupProgramDataList && eventLookupProgramDataList !== '' &&eventLookupProgramDataList.flowName !== 'UPLOAD_RECEIPT' && eventLookupProgramDataList.documentList && eventLookupProgramDataList.documentList !== '' && eventLookupProgramDataList.documentList.length > 0 ?
                                                            <>
                                                                <h5>Program Information: {eventLookupProgramDataList.programName}</h5>
                                                                <Grid item xs={12}>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <span>The following documents are required to complete the Patient Rebate Submission: </span>
                                                                    <br/>
                                                                    <span>Pharmacy Rebate </span>

                                                                    <ul>
                                                                        {eventLookupProgramDataList.documentList.map(doc => (
                                                                            <li key={doc.documentName}> {doc.documentName} </li>
                                                                        ))}

                                                                    </ul>
                                                                    <span>Medical Rebate </span>
                                                                    <ul>
                                                                        <li>Medical Insurance card (both front and back)</li>
                                                                        <li>Explanation of Benefits form from primary insurance (EOB)</li>
                                                                        <span>OR</span>
                                                                        <li>Provider statement showing patient's responsible charges</li>
                                                                    </ul>
                                                                </Grid>
                                                            </>

                                                            :

                                                            eventLookupCardId !== '' && eventLookupProgramDataList && eventLookupProgramDataList !== '' && eventLookupProgramDataList.programName !== '' ?
                                                                <h5>Program Information: {eventLookupProgramDataList.programName}</h5>

                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </>

                                            }
                                        </div>
                                    </div>
                                </div>

                                {patientInfo.type !== "card" && startDateNameLookup === '' && endDateNameLookup === ''  && cardStatusNameLookup === '' &&

                                <div className="flow-option-block">
                                    {/* <div className="two-col-spaced-nomargin card-benefit-section"> */}
                                    <h5>Co-pay Card Information/Benefit Information </h5>
                                    <br />
                                    <p style={{ color: 'red' }}>Patient Information is not available</p>
                                </div>
                                }

                                {eventLookup &&
                                <div className="flow-option-block">
                                    {/* <div className="two-col-spaced-nomargin card-benefit-section"> */}
                                    <h5>Co-pay Card Information/Benefit Information </h5>
                                    <span style={{color: "red", display:"none"}}>      <br/>Patient information is not available.</span>
                                    <br />
                                    <h6 className="benefit-subheader">Co-pay Card Information </h6>
                                    <div className="clear">
                                        <div className="m-block  fl ">
                                            {patientInfo.startDate && patientInfo.endDate && patientInfo.startDate !== '' && patientInfo.endDate !== '' ?
                                                <>
                                                    <p>Your card was activated on {patientInfo.startDate} and is approved for use beginning {patientInfo.startDate} through the program expiration date, {patientInfo.endDate}.</p>
                                                    <br/>
                                                    {patientInfo.cardStatus && patientInfo.cardStatus !== '' && patientInfo.cardStatus !== null ?
                                                        <>
                                                            <p>The card is currently {patientInfo.cardStatus}</p>
                                                            <br/>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                    <p className="benefit-bold">Expiration date is subject to change and will include re-enrollment information. If you have lost your card and it is currently Active, you can copy the fields below and present them to your pharmacy to receive your savings.
                                                        PLEASE NOTE: The information contained below is not needed for medical rebate support.
                                                    </p>
                                                    <br />
                                                    <SimpleTable1 patientInfo={patientInfo} progDataList={eventLookupProgramDataList} eventLookupCardId={eventLookupCardId}/>
                                                </>

                                                : startDateNameLookup && endDateNameLookup && startDateNameLookup !== '' && endDateNameLookup !== '' ?

                                                    <>
                                                        <p>Your card was activated on {startDateNameLookup} and is approved for use beginning {startDateNameLookup} through the program expiration date, {endDateNameLookup}.</p>
                                                        <br/>
                                                        {cardStatusNameLookup && cardStatusNameLookup !== '' && cardStatusNameLookup !== null ?
                                                            <>
                                                                <p>The card is currently {cardStatusNameLookup}</p>
                                                                <br/>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                        }
                                                        <p className="benefit-bold">Expiration date is subject to change and will include re-enrollment information. If you have lost your card and it is currently Active, you can copy the fields below and present them to your pharmacy to receive your savings.
                                                            PLEASE NOTE: The information contained below is not needed for medical rebate support.
                                                        </p>
                                                        <br />
                                                        {/* here */}
                                                        <SimpleTable1 patientInfo={patientInfo} progDataList={eventLookupProgramDataList} eventLookupCardId={eventLookupCardId}/>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="benefit-bold">Expiration date is subject to change and will include re-enrollment information. If you have lost your card and it is currently Active, you can copy the fields below and present them to your pharmacy to receive your savings.
                                                            PLEASE NOTE: The information contained below is not needed for medical rebate support.
                                                        </p>
                                                        <br />
                                                        <SimpleTable1 patientInfo={patientInfo} progDataList={eventLookupProgramDataList} eventLookupCardId={eventLookupCardId}/>
                                                    </>

                                            }


                                        </div>
                                    </div>
                                    {/* </div>
                                    <div className="two-col-spaced-nomargin card-benefit-section"> */}
                                    <br /><br />
                                    <h6 className="benefit-subheader">Benefit Information</h6>
                                    <div className="clear">


                                        {eventLookupClinetInfo !== "" && patientInfo.type !== "card" && eventLookup  ?
                                            <div className="m-block fl ">
                                                { patientInfo.programList.map(prg => prg.programName === patientInfo.programName) === "Information Unavailable" && (eventLookupClinetInfo === "Information Unavailable")  ?
                                                    <p style={{ color: 'red' }}>Benefit Information is not available at this time</p>
                                                    :

                                                    <>
                                                        {eventLookupClinetInfo === "Information Unavailable" ?
                                                            <p style={{ color: 'red' }} >Benefit Information is not available at this time</p>
                                                            :
                                                            <>
                                                                <p className="benefit-bold">The following information is not meant to be a complete representation of your benefits. For additional information, please contact your primary insurance provider. </p>
                                                                <br/>
																	<p> {eventLookupClinetInfo &&  htmlFrom(eventLookupClinetInfo)} </p>
                                                                <br/>
                                                                <SimpleTable2 patientInfo={patientInfo} eventLookup={eventLookup} progDataList={eventLookupProgramDataList} eventLookupCardId={eventLookupCardId} />
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            :
                                            patientInfo.type === "card"  ?

                                                <div className="m-block">

                                                    {patientInfo.programList.length === 1 && (patientInfo.programList[0].clientInfo === "" || patientInfo.programList[0].clientInfo === "Information Unavailable")  ?

                                                        <p style={{ color: 'red' }}>Benefit Information is not available at this time</p>
                                                        :
                                                        <>
                                                            <p className="benefit-bold">The following information is not meant to be a complete representation of your benefits. For additional information, please contact your primary insurance provider. </p>
                                                            <br/>
                                                            <p> {patientInfo.programList[0].clientInfo &&  htmlFrom(patientInfo.programList[0].clientInfo)} </p>
                                                            <br/>
                                                            <SimpleTable2 patientInfo={patientInfo} eventLookup={eventLookup} progDataList={eventLookupProgramDataList} eventLookupCardId={eventLookupCardId}  />
                                                        </>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>

                                        }
                                    </div>
                                    {/* </div> */}
                                </div>
                                }
                                <div className="flow-option-block">
                                    <h5>Patient Information</h5>
                                    <div className="clear">
                                        <div className="m-block fl two-col-spaced-nomargin   ">
                                            <MyTextInput label="* Patient Address 1" name="patientadd1"
                                                         readOnlyFlag={patientAddChk}/>
                                        </div>
                                        <div className="m-block fl two-col-spaced ">
                                            <MyTextInput InputLabelProps={{shrink: true,}} label="Patient Address 2"
                                                         name="patientadd2"
                                                         readOnlyFlag={patientAddChk}/>
                                        </div>
                                    </div>
                                    <div className="clear">
                                        <div className="m-block fl two-col-spaced-nomargin   ">
                                            <MyTextInput label="* Patient City" name="patientcity"
                                                         readOnlyFlag={patientAddChk}/>
                                        </div>
                                        <div className="m-block fl two-col-spaced ">

                                            <div className="m-block fl two-sm-col-spaced">
                                                <StateSelect label="* Patient State" name="patientstate"
                                                             parentprops={props} readOnlyFlag={patientAddChk}/>
                                            </div>
                                            <div className="m-block fl two-sm-col-spaced-r  ">
                                                <MyTextInput label="* Patient Zip Code" name="patientzip"
                                                             readOnlyFlag={patientAddChk}/></div>
                                        </div>
                                    </div>
                                    <div className="clear">
                                        <div className="m-block fl two-col-spaced-nomargin   ">
                                            <MyTextInput label="* Patient Phone Number" name="patientphone"
                                                         readOnlyFlag={patientAddChk}/>
                                        </div>
                                        {/* patientInfo.programList[0].customerOrgId === 57
                                        eventLookupProgramDataList.customerOrgId === 57 */}
                                        {patientInfo.type === "card" && patientInfo.programList[0].customerOrgId === 57
                                        && patientInfo.orgProgCamId != 7544 ?
                                            <div className="m-block fl two-col-spaced ">
                                                <MyTextInput label="Patient Email Address (For providing rebate updates)"
                                                             name="patientemail"
                                                             readOnlyFlag={patientAddChk}/>
                                            </div>
                                            :
                                            patientInfo.type === "card" && patientInfo.programList[0].customerOrgId === 57
                                            && patientInfo.orgProgCamId === 7544 ?
                                                <div className="m-block fl two-col-spaced ">
                                                    <MyTextInput label="* Patient Email Address (For providing rebate updates)"
                                                                 name="patientemail"
                                                                 readOnlyFlag={patientAddChk}/>
                                                </div>
                                                :
                                                patientInfo.type === "card" && patientInfo.programList[0].customerOrgId !== 57 ?
                                                    <div className="m-block fl two-col-spaced ">
                                                        <MyTextInput label="* Patient Email Address (For providing rebate updates)"
                                                                     name="patientemail"
                                                                     readOnlyFlag={patientAddChk}/>
                                                    </div>
                                                    :

                                                    patientInfo.type !== "card" && eventLookup &&  eventLookupProgramDataList.customerOrgId === 57
                                                    && patientInfo.orgProgCamId != 7544 ?
                                                        <div className="m-block fl two-col-spaced ">
                                                            <MyTextInput label="Patient Email Address (For providing rebate updates)"
                                                                         name="patientemail"
                                                                         readOnlyFlag={patientAddChk}/>
                                                        </div>
                                                        :
                                                        patientInfo.type !== "card" && eventLookup &&  eventLookupProgramDataList.customerOrgId === 57
                                                        && patientInfo.orgProgCamId === 7544 ?
                                                            <div className="m-block fl two-col-spaced ">
                                                                <MyTextInput label="* Patient Email Address (For providing rebate updates)"
                                                                             name="patientemail"
                                                                             readOnlyFlag={patientAddChk}/>
                                                            </div>
                                                            :
                                                            <div className="m-block fl two-col-spaced ">
                                                                <MyTextInput label="* Patient Email Address (For providing rebate updates)"
                                                                             name="patientemail"
                                                                             readOnlyFlag={patientAddChk}/>
                                                            </div>
                                        }
                                    </div>
                                    <div className="clear">
                                        {eventLookup &&
                                        <div className="m-block fl two-col-spaced-nomargin ">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={patientAddChk}
                                                        onChange={(event) => {
                                                            try{
                                                                if (event.target.checked) {
                                                                    event.preventDefault();
                                                                    props.handleSubmit();
                                                                    setBoxChecked(true);
                                                                    //  props.setFieldValue("confirmMailAdress", true, false);
                                                                } else {
                                                                    setPatientAddChk(false)
                                                                    setShowFields(true)
                                                                    //   props.setFieldValue("confirmMailAdress", false, false);
                                                                }
                                                            }catch(e){
                                                                console.log(" ERROR from check -->"+ e)
                                                            }

                                                        }}
                                                        name="confirmMailAdress"
                                                        value={patientAddChk}
                                                        color="primary"
                                                    />
                                                }
                                                label="* Please check the box to confirm mailing address"
                                            />
                                        </div>
                                        }
                                        {
                                            loadIcon ? <>
                                                <div className="m-block fl two-col-spaced-nomargin ">
                                                    <br></br><br></br><CircularProgress/></div>
                                            </> : <></>
                                        }
                                    </div>

                                    <>
                                        {addrErr ?
                                            <>
                                                <span style={{color: "red"}}> {addrErrMsg} </span>
                                                <br></br>
                                            </>
                                            :
                                            <></>
                                        }
                                    </>
                                </div>
                                {


                                    patientAddChk &&
                                    (
                                        (patientInfo.type === "card"  &&
                                            (patientInfo.flowName === "ONLINE_SUBMISSION" || patientInfo.flowName === "PRINT_AND_SUBMIT")
                                        )
                                        ||
                                        (patientInfo.type !== "card" && eventLookupProgramDataList &&
                                            (eventLookupProgramDataList.flowName === "ONLINE_SUBMISSION" || eventLookupProgramDataList.flowName === "PRINT_AND_SUBMIT"))
                                    ) &&
                                    <>
                                        <div className="flow-option-block">
                                            <h5>Pharmacy/Provider Information</h5>
                                            <p>For pharmacy rebate please provide pharmacy information. For medical rebate please provide provider information below.</p>
                                            <div className="clear">
                                                <div className="m-block fl two-col-spaced-nomargin   ">
                                                    <CertTextInput label="* Pharmacy/Provider Name" name="pharmacyname"/>

                                                </div>
                                                <div className="m-block fl two-col-spaced ">
                                                </div>
                                            </div>
                                            <div className="clear">
                                                <div className="m-block fl two-col-spaced-nomargin ">
                                                    <CertTextInput label="* Pharmacy/Provider Address 1"
                                                                   name="pharmacyaddress1"/>
                                                </div>
                                                <div className="m-block fl two-col-spaced ">
                                                    <CertTextInput label="Pharmacy/Provider Address 2"
                                                                   name="pharmacyaddress2"/>
                                                </div>
                                            </div>
                                            <div className="clear">
                                                <div className="m-block fl two-col-spaced-nomargin ">
                                                    <CertTextInput label="* Pharmacy/Provider City" name="pharmacycity"/>
                                                </div>
                                                <div className="m-block fl two-col-spaced ">
                                                    <div className="m-block fl two-sm-col-spaced">
                                                        <StateSelect label="* Pharmacy/Provider State" name="pharmacystate"
                                                                     parentprops={props}/>

                                                    </div>
                                                    <div className="m-block fl two-sm-col-spaced-r  ">
                                                        <CertTextInput label="* Pharmacy/Provider Zip Code"
                                                                       name="pharmacyzip"
                                                                       labelprops={{style: {fontSize: 15}}}/>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="flow-option-block">
                                            <h5>Drug Information</h5>
                                            <div className="clear">
                                                <div className="m-block fl two-col-spaced-nomargin   ">
                                                    <CertTextInput label="* Medication Name &#38; Strength"
                                                                   name="medicationname" inputProps={{ maxLength: 250 }} />

                                                </div>
                                                <div className="m-block fl two-col-spaced   ">
                                                    <CertTextInput label="* Prescription Number"
                                                                   helperText="N/A for medical benefit" name="prescriptionnum"/>
                                                    <p className="small">N/A for medical benefit, please enter "MB"</p>
                                                </div>
                                            </div>
                                            <div className="clear">
                                                <div className="m-block fl two-col-spaced-nomargin   ">
                                                    <div className="m-block fl two-sm-col-spaced">
                                                        <CertTextInput label="* Quantity/Units Supplied"
                                                                       name="medicationquantity"/>
                                                    </div>
                                                    <div className="m-block fl two-sm-col-spaced-r  ">
                                                        <CertTextInput label="* Days Supplied"
                                                                       helperText="N/A for medical benefit" name="medicationdays"/>
                                                        <p className="small">N/A for medical benefit, please enter "MB"</p>
                                                    </div>
                                                </div>
                                                <div className="m-block fl two-col-spaced   ">
                                                    <div className="m-block fl two-sm-col-spaced">
                                                        <CertTextInput label="* Amount Paid by Patient"
                                                                       name="amountpaid"/>

                                                    </div>
                                                    <div className="m-block fl two-sm-col-spaced-r  ">

                                                        <ReceiptDateUtils label="* Documentation Receipt Date" name="reciptdate"
                                                                          errmsg={dobErr} parentprops={props}
                                                                          activeDate={activeDate}
                                                                          endDated={endDated}
                                                                          
                                                                          labelprops={{style: {fontSize: 13}}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                }
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                >

                                    {
                                        printLoadIcon ? <>
                                            <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                                            <div class="modal-body">
                                                We have received your submission and are processing your
                                                request.<br></br>
                                                <strong>Please DO NOT press the browser back or the refresh
                                                    button until the process is completed.</strong>
                                                <br></br>
                                                No further action is needed at this point. Please contact
                                                1-866-566-6446 for assistance.
                                            </div>

                                            <div className="m-block text-center">
                                                <br></br><CircularProgress/></div>
                                            <div className="m-block text-center"><br></br></div>
                                        </> : <></>
                                    }

                                    {successChk &&
                                    <>
                                        <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                                        {props.values.onlineUpload ?
                                            <div class="modal-body">
                                                Your rebate request has been successfully submitted.
                                            </div>
                                            : <>
                                                <div className="modal-body">
                                                    Your rebate request has been successfully
                                                    submitted. <strong>Please download the form by clicking
                                                    below and submit by mail with supporting documents as
                                                    described on the form.</strong>
                                                </div>
                                            </>
                                        }
                                        <div class="modal-footer">
                                            <div className="m-block block-center   ">
                                                {/*<div>
                                                                <Button id="printConfirmation" disabled>
                                                                        <span
                                                                            className="button btn-primary">CONFIRMATION</span>
                                                                </Button>
                                                            </div>*/}
                                                <br></br>
                                                <div>
                                                    <Button id="printDownload" onClick={downloadFile}><span
                                                        className="button btn-primary">DOWNLOAD</span></Button>
                                                </div>
                                                <br></br>
                                                <div>
                                                    <Button id="submitAnotherPrint" onClick={submitAnother}><span
                                                        className="button btn-secondary">SUBMIT ANOTHER REQUEST</span></Button>
                                                </div>
                                            </div>
                                            <br></br>
                                        </div>
                                    </>
                                    }
                                    {errorChk &&
                                    <>
                                        <h3 class="modal-title error-msg"
                                            id="exampleModalLongTitle"><br></br>Submission Error&#33;  </h3>
                                        <div class="modal-body error-msg">
                                            {serverErrMsg}<br></br><br></br>{/* Please try again later.*/}
                                        </div>
                                        <div class="modal-footer">
                                            <div className="m-block block-center   ">

                                                <div>
                                                    <Button onClick={handleClose}><span
                                                        className="button btn-primary">Close</span></Button>
                                                </div>
                                                <br></br>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </Dialog>


                                <div className="clear">



                                    {patientInfo.type === "card" ?

                                        <div className="m-block  block-center ">
                                            {
                                                patientAddChk && patientInfo.flowName === "ONLINE_SUBMISSION" &&
                                                <Button type="submit" name="onlinesubmitbtn"><span
                                                    className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button>
                                            }
                                            {
                                                patientAddChk && patientInfo.flowName === "PRINT_AND_SUBMIT" &&
                                                <Button type="submit" name="printsubmitbtn"><span
                                                    className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                            }
                                            {
                                                patientAddChk && patientInfo.flowName === "UPLOAD_RECEIPT" &&
                                                <Button type="submit" name="uploadreceiptbtn"><span
                                                    className="button btn-primary">UPLOAD RECEIPTS</span></Button>
                                            }

                                            {/* <Button type="submit" name="onlinesubmitbtn"><span
                                                            className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button> */}
                                            {/* <Button type="submit" name="printsubmitbtn"><span
                                                                            className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                                                            <Button type="submit" name="printsubmitbtn"><span
                                                                                    className="button btn-primary">UPLOAD RECEIPTS</span></Button> */}
                                            {/* <>
                                                                {
                                                                    patientAddChk && patientInfo.flowName === "ONLINE_SUBMISSION" ?
                                                                        <Button type="submit" name="onlinesubmitbtn"><span
                                                                            className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button>
                                                                        :
                                                                        patientAddChk && patientInfo.flowName === "PRINT_AND_SUBMIT" ?
                                                                            <Button type="submit" name="printsubmitbtn"><span
                                                                                className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                                                            :
                                                                            patientAddChk && patientInfo.flowName === "UPLOAD_RECEIPT" ?
                                                                                <Button type="submit" name="printsubmitbtn"><span
                                                                                    className="button btn-primary">UPLOAD RECEIPTS</span></Button>
                                                                                :
                                                                                <></>
                                                                }

                                                            </> */}
                                        </div>

                                        :


                                        <div className="m-block  block-center ">
                                            {
                                                patientAddChk && eventLookupProgramDataList && eventLookupProgramDataList !== '' && eventLookupProgramDataList.flowName === "ONLINE_SUBMISSION" &&
                                                <Button type="submit" name="onlinesubmitbtn"><span
                                                    className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button>
                                            }
                                            {
                                                patientAddChk && eventLookupProgramDataList && eventLookupProgramDataList !== '' && eventLookupProgramDataList.flowName === "PRINT_AND_SUBMIT" &&
                                                <Button type="submit" name="printsubmitbtn"><span
                                                    className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                            }
                                            {
                                                patientAddChk && eventLookupProgramDataList && eventLookupProgramDataList !== '' && eventLookupProgramDataList.flowName === "UPLOAD_RECEIPT" &&
                                                <Button type="submit" name="uploadreceiptbtn"><span
                                                    className="button btn-primary">UPLOAD RECEIPTS</span></Button>
                                            }

                                            {/* <Button type="submit" name="onlinesubmitbtn"><span
                                                        className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button> */}
                                            {/* <Button type="submit" name="printsubmitbtn"><span
                                                                        className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                                                        <Button type="submit" name="printsubmitbtn"><span
                                                                                className="button btn-primary">UPLOAD RECEIPTS</span></Button> */}
                                            {/* <>
                                                            {
                                                                patientAddChk && patientInfo.flowName === "ONLINE_SUBMISSION" ?
                                                                    <Button type="submit" name="onlinesubmitbtn"><span
                                                                        className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button>
                                                                    :
                                                                    patientAddChk && patientInfo.flowName === "PRINT_AND_SUBMIT" ?
                                                                        <Button type="submit" name="printsubmitbtn"><span
                                                                            className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                                                        :
                                                                        patientAddChk && patientInfo.flowName === "UPLOAD_RECEIPT" ?
                                                                            <Button type="submit" name="printsubmitbtn"><span
                                                                                className="button btn-primary">UPLOAD RECEIPTS</span></Button>
                                                                            :
                                                                            <></>
                                                            }

                                                        </> */}
                                        </div>



                                    }

                                </div>

                                <br></br>

                                <div className="m-block  block-center ">
                                    <div className="m-block">
                                        <Button name="backbtn" onClick={() => nextpage(`/web`)}><span
                                            className="button btn-secondary">BACK</span></Button>
                                    </div>
                                </div>
                                <br></br><br></br><br></br><br></br><br></br>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default PatientCertification