import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {Formik} from 'formik';
import StepsImage from '../common/StepsImage';
import Grid from '@material-ui/core/Grid';
import * as yup from "yup";
import DOMPurify from "isomorphic-dompurify";
import {SAVE_PATIENT_INFO} from "../../action/PatientLokUpAction";
import StateSelect from "../common/StateSelect";
import {setEnvVariables} from "../../config/EnvUtil";
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import CertTextInput from "../common/CertTextInput";
import ReceiptDateUtils from "../common/ReceiptDateUtils";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {encryptFunction} from '../common/Util';

const checkNull = (element) => {
    if (element != null) {
        return element;
    } else {
        return ""
    }
}

const dateFormat = (dob) => {
    var dd = dob.getDate();
    var mm = dob.getMonth() + 1;
    var yyyy = dob.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return (mm + '/' + dd + '/' + yyyy);
}

const receiptDateSet = (dateVal) => {
    if (dateVal !== null) {
        //alert(dateVal);
        return new Date(dateVal);
    } else {
        return null;
    }
}

const PharmacyandPrescriptionInfo = () => {
    let endpoint = setEnvVariables(window.location.hostname);
    // let match = useRouteMatch();
    let history = useHistory();
    const patientInfo = useSelector(state => state.patientInfo);
    // const [acceptedTerms, setAcceptedTerms] = useState(false);
    const dobErr = "This field is required in MM/DD/YYYY format and should not be future date.";
    const [serverErr, setServerErr] = useState(false);
    const [serverErrMsg, setServerErrMsg] = useState("");
    const [eventLookup, setEventLookup] = useState(patientInfo.eventLookup);
    const [errorChk, setErrorChk] = useState(false);
    const [successChk, setSuccessChk] = useState(false);
    const [printResponse, setPrintResponse] = useState("");
    const [printFileName, setPrintFileName] = useState("");
    let submitChk = true;

    const setMaxQtyMessage = (x) => {
        let medicationquantitymsg = 'This field is required upto 4 digits with decimal at any point.';
        if (x !== 0 && x <= 10000) {
            medicationquantitymsg = 'This field should be less than or equal to ' + x + '.';
        }
        return medicationquantitymsg;
    }

    const setMaxDaysSuppliedMessage = (y) => {
        let medicationdaysMsg = 'This field must be less than or equal to 360 days.';
        if (y !== 0 && y < 361) {
            medicationdaysMsg = 'This field should be less than or equal to ' + y + '.';
        }
        return medicationdaysMsg;
    }

    const populateMaxQty = (qty) => {
        if (qty === 0 || qty > 10000) {
            qty = 10000;
        } else {
            qty += 1;
        }
        return qty;
    }

    const populateMaxDaysSupplied = (days) => {
        if (days === 0 || days > 360) {
            days = 361;
        } else {
            days += 1;
        }
        return days;
    }

    const [maxQtyMsg, setMaxQtyMsg] = useState(setMaxQtyMessage(patientInfo.maxQty));
    const [maxQty, setMaxQty] = useState(populateMaxQty(patientInfo.maxQty));
    const [maxDaysSuppliedMsg, setMaxDaysSuppliedMsg] = useState(setMaxDaysSuppliedMessage(patientInfo.maxDaysSupplied));
    const [maxDaysSupplied, setMaxDaysSupplied] = useState(populateMaxDaysSupplied(patientInfo.maxDaysSupplied));
    const [activeDate, setActiveDate] = useState(new Date(patientInfo.activeDate));

    const setMessages = (x, y, z) => {
        setMaxQtyMsg(setMaxQtyMessage(x));
        setMaxQty(populateMaxQty(x));
        setMaxDaysSuppliedMsg(setMaxDaysSuppliedMessage(y));
        setMaxDaysSupplied(populateMaxDaysSupplied(y));
        setActiveDate(new Date(z.trim()));
    }

    const dispatch = useDispatch();
    var validationSchema;

    const [open, setOpen] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(true);
    const [printLoadIcon, setPrintLoadIcon] = useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const updateEventLookup = (x) => setEventLookup(x);
    //   const submitAnother = () => history.push('/web');
    const submitAnother = () => window.location.href = "/web/";

    const downloadFile = () => {
        const url = window.URL.createObjectURL(new Blob([printResponse]));
        const link = document.createElement('a');
        link.href = DOMPurify.sanitize(url);
        let name = generateFileName(printFileName);
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const generateFileName = (name) => {
        if (name.includes("gilead_dmr_form")) {
            return "gilead_dmr_form.pdf";
        } else if (name.includes("novartis_dmr_form")) {
            return "novartis_dmr_form.pdf";
        } else if (name.includes("kowa_dmr_form")) {
            return "kowa_dmr_form.pdf";
        } else if (name.includes("merck_dmr_0026_form")) {
            return "merck_dmr_0026_form.pdf";
        } else if (name.includes("merck_dmr_0027_form")) {
            return "merck_dmr_0027_form.pdf";
        } else {
            return name;
        }
    }

    validationSchema = yup.object().shape({
            pharmacyname: yup.string()
                        .required('This field is required.')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
            pharmacyaddress1: yup.string()
                        .required('This field is required. ')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
               
            pharmacyaddress2: yup.string()
                        .max(40, 'This field should not exceed 40 characters.'),
            pharmacycity: yup.string()
                        .required('This field is required.')
                        .max(40, 'This field should not exceed 40 characters.')
                        .min(1, 'This field is required.'),
            pharmacyzip: yup.string()
                        .required('This field must be 5 digits long.')
                        .matches(/^[0-9]+$/, 'This field must be 5 digits long.')
                        .min(5, 'This field must be 5 digits long.')
                        .max(5, 'This field must be 5 digits long.'),

            pharmacystate: yup.string()
                        .required('This field is required.')
                        .min(1, 'This field is required.'),
            medicationname: yup.string()
                        .required('This field is required.')
                        .min(1, 'This field is required.'),
            prescriptionnum: yup.string()
                        .required('This field is required in digits.')
                        .matches(/^[0-9]+$/, 'This field is required in digits.')
                        .min(1, 'This field is required in digits.'),
            medicationquantity: yup.number()
                        .typeError('This field is required upto 4 digits with decimal at any point.')
                        .required('This field is required upto 4 digits with decimal at any point.')
                        .moreThan(0, 'This field must be greater than 0.')
                        .lessThan(maxQty, maxQtyMsg),
            medicationdays: yup.number()
                        .typeError('This field is required upto 4 digits with decimal at any point.')
                        .required('This field is required in digits.')
                        .integer('This field is required in digits.')
                        .positive('This field is required in digits.')
                        .moreThan(0, 'This field must be greater than 0.')
                        .lessThan(maxDaysSupplied, maxDaysSuppliedMsg),
            amountpaid: yup.number()
                        .typeError('This field is required in decimal.')
                        .required('This field is required in decimal.')
                        .moreThan(0, 'This field is required in decimal.'),
            reciptdate: yup.date()
                .required("DOB is required in MM/DD/YYYY format and should not be future date.")
        });

    var formSchema = {
        programNameSel: checkNull(patientInfo.orgProgCamId),
        onlineUpload: checkNull(patientInfo.onlineUpload),
        pharmacyname: patientInfo.pharmacyname,
        pharmacyaddress1: patientInfo.pharmacyaddress1,
        pharmacycity: patientInfo.pharmacycity,
        pharmacyaddress2: patientInfo.pharmacyaddress2,
        pharmacystate: patientInfo.pharmacystate,
        pharmacyzip: patientInfo.pharmacyzip,
        medicationname: patientInfo.medicationname,
        medicationquantity: patientInfo.medicationquantity,
        medicationdays: patientInfo.medicationdays,
        prescriptionnum: patientInfo.prescriptionnum,
        acceptedTermsChk: false,
        amountpaid: patientInfo.amountpaid,
        reciptdate: receiptDateSet(patientInfo.reciptdate),
        opcEventId: checkNull(patientInfo.opcEventId),
        cardId: patientInfo.cardId,

    };


    const nextpage = (url) => {
        history.push(url)
    }

    return (

        <Formik
            initialValues={formSchema}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                const axios = require('axios');

                let newDate = new Date();
                if (values.reciptdate >= activeDate && values.reciptdate <= newDate) {

                    let docList, programName, orgProgCamId, orgProgCamRecipientId, customerOrgId, cardId;
                    let programList = patientInfo.programList;

                    programList.map(program => {
                        if (program.orgProgCamId === patientInfo.orgProgCamId) {
                            docList = program.documentList;
                            console.log(program.documentList);
                            orgProgCamId = program.orgProgCamId;
                            orgProgCamRecipientId = program.orgProgCamRecipientId;
                            programName = program.programName;
                            customerOrgId = program.customerOrgId;
                            cardId = program.cardId;
                            // onlineUpload = program.onlineUpload;
                        }
                    });

                    if (values.onlineUpload || patientInfo.onlineUpload) {
                        let patientFName = patientInfo.firstName;
                        let patientLName = patientInfo.lastName;
                        let signatureName = patientFName.trim() + " " + patientLName.trim();

                        dispatch({
                            type: SAVE_PATIENT_INFO, payload: {
                                "orgProgCamId": orgProgCamId,
                                "orgProgCamRecipientId": orgProgCamRecipientId,
                                "recipientId": patientInfo.recipientId,
                                "email": patientInfo.email,
                                "phoneNumber": patientInfo.phoneNumber,
                                "programList": patientInfo.programList,
                                "docList": docList,
                                "address": patientInfo.address,
                                "type": patientInfo.type,
                                "programName": programName,
                                "onlineUpload": patientInfo.onlineUpload,
                                "firstName": patientInfo.firstName,
                                "lastName": patientInfo.lastName,
                                "dob": patientInfo.dob,
                                "pharmacyname": values.pharmacyname,
                                "pharmacyaddress1": values.pharmacyaddress1,
                                "pharmacyaddress2": values.pharmacyaddress2,
                                "pharmacycity": values.pharmacycity,
                                "pharmacystate": values.pharmacystate,
                                "pharmacyzip": values.pharmacyzip,
                                "medicationname": values.medicationname,
                                "medicationquantity": values.medicationquantity,
                                "medicationdays": values.medicationdays,
                                "prescriptionnum": values.prescriptionnum,
                                "amountpaid": values.amountpaid,
                                "reciptdate": dateFormat(values.reciptdate),
                                "fileList": [],
                                "signature": signatureName,
                                "relationship": "Self",
                                "fontValue": "",
                                "font1": false,
                                "font2": false,
                                "font3": false,
                                "fontselect": false,
                                "fileselect": false,
                                "signdate": new Date(),
                                "maxFileListChk": true,
                                "customerOrgId": customerOrgId,
                                "opcEventId": values.opcEventId, // Handle in back button
                                "eventLookup": patientInfo.eventLookup,
                                "cardId": cardId,
                                "maxQty": maxQty,
                                "maxDaysSupplied": maxDaysSupplied,
                                "activeDate": activeDate
                            }
                        });
                        history.push({
                            pathname: '/web/UploadAndAttest',
                            search: 'onlineSubmission',
                            state: { detail: 'some_value' }
                        });
                        //nextpage(`/web/UploadAndAttest/rebateflow`);
                        window.location.href = "#root";
                    } else {

                        if (submitChk) {
                            submitChk = false;
                                setSubmitBtn(false);
                                setPrintLoadIcon(true);
                                setSuccessChk(false);
                                setErrorChk(false);
                                handleClickOpen();
                               
                                let patientInformation = {
                                    "firstName": patientInfo.firstName,
                                    "lastName": patientInfo.lastName,
                                    "dob": patientInfo.dob,
                                    "addressLine1": patientInfo.address.addressLine1,
                                    "addressLine2": patientInfo.address.addressLine2,
                                    "city": patientInfo.address.city,
                                    "state": patientInfo.address.state,
                                    "zip": patientInfo.address.zip,
                                    "phoneNumber": patientInfo.phoneNumber,
                                    "email": patientInfo.email
                                }
                                let pharmacyInfo = {
                                    "name": values.pharmacyname,
                                    "address1": values.pharmacyaddress1,
                                    "address2": values.pharmacyaddress2,
                                    "city": values.pharmacycity,
                                    "state": values.pharmacystate,
                                    "zip": values.pharmacyzip
                                }
                                let prescriptionInfo = {
                                    "name": values.medicationname,
                                    "quantity": values.medicationquantity,
                                    "daysSupplied": values.medicationdays,
                                    "number": values.prescriptionnum,
                                    "amount": values.amountpaid,
                                    "receiptDate": dateFormat(values.reciptdate)
                                }
                                let fileName;
                                let token = encryptFunction(endpoint);
                                const headers = {
                                    'X-Copay-API-Authorization': token.data,
                                    'X-Copay-API-IV': token.token
                                };
                                axios({
                                    method: "POST",
                                    url: endpoint.mail,
                                    headers: headers,
                                    data: {
                                        "orgProgCamRecipientId": orgProgCamRecipientId,
                                        "orgRecipientId": patientInfo.recipientId,
                                        "orgProgCamId": orgProgCamId,
                                        "onlineUpload": patientInfo.onlineUpload,
                                        "cardId": cardId,
                                        "opcEventId": values.opcEventId,
                                        "customerOrgId": customerOrgId,
                                        "patientInfo": patientInformation,
                                        "pharmacyInfo": pharmacyInfo,
                                        "prescriptionInfo": prescriptionInfo
                                    },
                                    responseType: 'arraybuffer',
                                })
                                    .then(response => {
                                        // console.log("Response from online submission service : " + response.status);
                                        // console.log("File Name : " + response.headers.pragma);
                                        fileName = response.headers.pragma;
                                        if (response.status === 200) {
                                            setSuccessChk(true);
                                            setPrintResponse(response.data);
                                            setPrintFileName(fileName);
                                            axios({
                                                method: "DELETE",
                                                url: endpoint.deleteFile + fileName,
                                                headers: headers,
                                            }).then(deleteResponse => {
                                                // console.log("Response from Delete file service : " + deleteResponse.status)
                                            }).catch(error => {
                                                console.log(error.message);
                                            });

                                            setPrintLoadIcon(false);

                                        } else if (response.status === 203) {
                                            setErrorChk(true);
                                            setSubmitBtn(true);
                                            setServerErr(true);
                                            const res = JSON.parse(Buffer.from(response.data).toString('utf8'));
                                            setServerErrMsg(res.errors.length === 0 ? "An error occurred while attempting to process your request." : res.errors);
                                            //handleClickOpen();
                                            setPrintLoadIcon(false);
                                        }

                                        submitChk = true;

                                    }).catch(error => {
                                    setSubmitBtn(true);
                                    setServerErr(true);
                                    setErrorChk(true);
                                    console.log(error);
                                    if (error.message === "Network Error") {
                                        setServerErrMsg("Network Error.");
                                    } else {
                                        setServerErrMsg("An error occurred while attempting to process your request.");
                                    }

                                    // handleClickOpen();
                                    setPrintLoadIcon(false);

                                    submitChk = true;

                                });

                            }

                        }
                    }
            }
            }
        >   
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <div className="main-content-wrapper">
                        <div className="nine-tenth-mobile block-center">
                            <div className="m-block fl  padding-r-lg " style={{width: '100%',}}>
                                <StepsImage></StepsImage>
                                <h1 className="modal-title"> PATIENT CERTIFICATION </h1>
                               
                                <h4><br></br>
                                    You must be 18 years of age or older and a resident of the United States, Puerto
                                    Rico, Guam or Virgin Island to request a rebate. All fields denoted with an asterisk
                                    are required.
                                </h4>  <br></br>
                                <div className="flow-option-block">
                                    {patientInfo.type === "card" ?
                                        <h5>Program Name: {patientInfo.programName}</h5> :
                                        <h5>Program Name: {patientInfo.prognameNameLookup}</h5> 
                                        }
                                        <h5>Program Information</h5>
                                    <div className="clear">
                                        <div className="m-block  fl ">
                                            {
                                                <>
                                                    <Grid item xs={12}>
                                                        <br></br>
                                                        <span>The following documents are required to complete the Patient Rebate Submission </span>
                                                        <br/>
                                                        <span>Pharmacy Rebate </span>
                                                        <ul>
                                                            {patientInfo.programList[0].documentList.map(doc => (
                                                                <li key={doc.documentName}> {doc.documentName} </li>
                                                            ))}

                                                        </ul>
                                                        <span>Medical Rebate </span>
                                                        <ul>
                                                            <li>Medical Insurance card (both front and back)</li>
                                                            <li>Explanation of Benefits form from primary insurance (EOB)</li>
                                                            <span>OR</span>
                                                            <li>Provider statement showing patient's responsible charges</li>
                                                        </ul>
                                                    </Grid>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                                        <>
                                            <div className="flow-option-block">
                                                <h5>Pharmacy Information</h5>
                                                <div className="clear">
                                                    <div className="m-block fl two-col-spaced-nomargin   ">
                                                        <CertTextInput label="* Pharmacy Name" name="pharmacyname"/>

                                                    </div>
                                                    <div className="m-block fl two-col-spaced ">
                                                    </div>
                                                </div>
                                                <div className="clear">
                                                    <div className="m-block fl two-col-spaced-nomargin ">
                                                        <CertTextInput label="* Pharmacy Address 1"
                                                                       name="pharmacyaddress1"/>
                                                    </div>
                                                    <div className="m-block fl two-col-spaced ">
                                                        <CertTextInput label="Pharmacy Address 2"
                                                                       name="pharmacyaddress2"/>
                                                    </div>
                                                </div>
                                                <div className="clear">
                                                    <div className="m-block fl two-col-spaced-nomargin ">
                                                        <CertTextInput label="* Pharmacy City" name="pharmacycity"/>
                                                    </div>
                                                    <div className="m-block fl two-col-spaced ">
                                                        <div className="m-block fl two-sm-col-spaced">
                                                            <StateSelect label="* Pharmacy State" name="pharmacystate"
                                                                         parentprops={props}/>

                                                        </div>
                                                        <div className="m-block fl two-sm-col-spaced-r  ">
                                                            <CertTextInput label="* Pharmacy Zip Code"
                                                                           name="pharmacyzip"/>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flow-option-block">
                                                <h5>Prescription Information</h5>
                                                <div className="clear">
                                                    <div className="m-block fl two-col-spaced-nomargin   ">
                                                        <CertTextInput label="* Medication Name &#38; Strength"
                                                                       name="medicationname"/>

                                                    </div>
                                                    <div className="m-block fl two-col-spaced   ">
                                                        <CertTextInput label="* Prescription Number"
                                                                       name="prescriptionnum"/>
                                                    </div>
                                                </div>
                                                <div className="clear">
                                                    <div className="m-block fl two-col-spaced-nomargin   ">
                                                        <div className="m-block fl two-sm-col-spaced">
                                                            <CertTextInput label="* Quantity Supplied"
                                                                           name="medicationquantity"/>
                                                        </div>
                                                        <div className="m-block fl two-sm-col-spaced-r  ">
                                                            <CertTextInput label="* Days Supplied"
                                                                           name="medicationdays"/>
                                                        </div>
                                                    </div>
                                                    <div className="m-block fl two-col-spaced   ">
                                                        <div className="m-block fl two-sm-col-spaced">
                                                            <CertTextInput label="* Amount Paid by Patient"
                                                                           name="amountpaid"/>

                                                        </div>
                                                        <div className="m-block fl two-sm-col-spaced-r  ">

                                                            <ReceiptDateUtils label="* Receipt Date" name="reciptdate"
                                                                              errmsg={dobErr} parentprops={props}
                                                                              activeDate={activeDate}/>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                disableBackdropClick={true}
                                                disableEscapeKeyDown={true}
                                            >

                                                {
                                                    printLoadIcon ? <>
                                                        <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                                                        <div class="modal-body">
                                                            We have received your submission and are processing your
                                                            request.<br></br>
                                                            <strong>Please DO NOT press the browser back or the refresh
                                                                button until the process is completed.</strong>
                                                            <br></br>
                                                            No further action is needed at this point. Please contact
                                                            1-866-566-6446 for assistance.
                                                        </div>

                                                        <div className="m-block text-center">
                                                            <br></br><CircularProgress/></div>
                                                        <div className="m-block text-center"><br></br></div>
                                                    </> : <></>
                                                }

                                                {successChk &&
                                                <>
                                                    <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                                                    {props.values.onlineUpload ?
                                                        <div class="modal-body">
                                                            Your rebate request has been successfully submitted.
                                                        </div>
                                                        : <>
                                                            <div className="modal-body">
                                                                Your rebate request has been successfully
                                                                submitted. <strong>Please download the form by clicking
                                                                below and submit by mail with supporting documents as
                                                                described on the form.</strong>
                                                            </div>
                                                        </>
                                                    }
                                                    <div class="modal-footer">
                                                        <div className="m-block block-center   ">
                                                            {/*<div>
                                                                <Button id="printConfirmation" disabled>
                                                                        <span
                                                                            className="button btn-primary">CONFIRMATION</span>
                                                                </Button>
                                                            </div>*/}
                                                            <br></br>
                                                            <div>
                                                                <Button id="printDownload" onClick={downloadFile}><span
                                                                    className="button btn-primary">DOWNLOAD</span></Button>
                                                            </div>
                                                            <br></br>
                                                            <div>
                                                                <Button id="submitAnotherPrint" onClick={submitAnother}><span
                                                                    className="button btn-secondary">SUBMIT ANOTHER REBATE</span></Button>
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                    </div>
                                                </>
                                                }
                                                {errorChk &&
                                                <>
                                                    <h3 class="modal-title error-msg"
                                                        id="exampleModalLongTitle"><br></br>Submission Error&#33;  </h3>
                                                    <div class="modal-body error-msg">
                                                        {serverErrMsg}<br></br><br></br>{/* Please try again later.*/}
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div className="m-block block-center   ">

                                                            <div>
                                                                <Button onClick={handleClose}><span
                                                                    className="button btn-primary">Close</span></Button>
                                                            </div>
                                                            <br></br>
                                                        </div>
                                                    </div>
                                                </>
                                                }
                                            </Dialog>

                                            <div className="clear">
                                                <div className="m-block  block-center ">
                                                    {/*  <>
                                                        {printLoadIcon &&
                                                        <div style={{textAlign: "center"}}>
                                                            <br></br><CircularProgress/><br></br>
                                                        </div>
                                                        }
                                                    </> */}
                                                    <>
                                                        {
                                                            props.values.onlineUpload ?
                                                                <Button type="submit" name="onlinesubmitbtn"><span
                                                                    className="button btn-primary"> CONTINUE ONLINE SUBMISSION </span></Button>
                                                                :
                                                                <>
                                                                    {submitBtn &&
                                                                    <Button type="submit" name="printsubmitbtn"><span
                                                                        className="button btn-primary">PRINT &#38; SUBMIT BY MAIL</span></Button>
                                                                    }
                                                                </>

                                                        }
                                                    </>
                                                </div>
                                            </div>
                                            <br></br>
                                        </>
                                <div className="m-block  block-center ">
                                    <div className="m-block">
                                        <Button name="backbtn" onClick={() => nextpage(`/web/PatientCertification`)}><span
                                            className="button btn-secondary">BACK</span></Button>
                                    </div>
                                </div>
                                <br></br><br></br><br></br><br></br><br></br>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
                                    }

export default PharmacyandPrescriptionInfo