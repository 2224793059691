import React from 'react';


const PreviewPrescriptionInformation = (props) => {
    return (

        <div className="flow-option-block">
            <div className="clear">
                <h3>Drug Information</h3> <br/>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Medication Name & Strength</div>
                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.medicationname}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Prescription Number
                            <br/>
                            <p className="small">N/A for medical benefit, please enter "MB"</p>
                            </div>
                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.prescriptionnum}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Quantity Supplied</div>
                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.medicationquantity}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label"> Days Supplied
                                <br/>
                                <p class="small">N/A for medical benefit, please enter "MB"</p>
                            </div>
                            <div className="preview-value"><p
                                className="preview-text">{props.patientInfo.medicationdays}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label">Amount Paid by Patient</div>
                            <div className="preview-value"><p
                                className="preview-text">    {props.patientInfo.amountpaid}</p></div>
                        </div>
                    </div>
                </div>
                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <div className="preview-label">Documentation Receipt Date</div>
                            <div className="preview-value"><p
                                className="preview-text"> {props.patientInfo.reciptdate} </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PreviewPrescriptionInformation