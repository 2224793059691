import React, {useState} from 'react';
import {useField} from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//import Select from '@material-ui/core/Select';

var statesList = [
    {"code": "", "name": "Relationship to Patient"},
    {"code": "Self", "name": "Self"},
    {"code": "Parent", "name": "Parent"},
    {"code": "Guardian", "name": "Guardian"}
];


const chkReadOnly = (val) => {
    let chk = false;
    if (val === true) {
        chk = true;
    }
    return chk;
}

const RelationSelect = ({label, readOnlyFlag, ...props}) => {

    const [field, meta] = useField(props);
    const [err, setErr] = useState(false);
    //const [state, setState] = useState();
    const [xx, setXX] = useState(true);


    const handleChange = (event) => {
        setXX(false);
        props.parentprops.setFieldValue(props.name, event.target.value, false);
        if (event.target.value !== "") {
            setErr(false);
            if (event.target.value === 'Self') {
                props.relationlookup(true);
                props.parentprops.setFieldValue("signature", props.patientname, false);
            } else {
                props.parentprops.setFieldValue("signature", "", false);
                props.relationlookup(false);
            }
        } else {
            setErr(true);
        }
    };

    return (
        <TextField   {...field} {...props}
                     label={label}
                     id={props.name}
                     select
                     inputProps={{readOnly: chkReadOnly(readOnlyFlag)}}
                     name={props.name}
                     value={meta.value}
                     onChange={handleChange}
            // helperText={err ? "select select" : ""}
            // error={err ? true : false}
                     helperText={(xx && meta.error) || err ? meta.error : ""}
                     error={(xx && meta.error) || err ? true : false}
        >
            {statesList.map((state) => (
                <MenuItem key={state.code} value={state.code} selected={true}>
                    {state.name}
                </MenuItem>
            ))}
        </TextField>

    )

};

export default RelationSelect