import {variables as localhostVariables} from './config_dev';
import {variables as testVariables} from './config_test';
import {variables as devVariables} from './config_dev';
import {variables as prodVariables} from './config_prod';

const environment = [
    {key: "localhost", value:localhostVariables},
    {key: "patientrebateonline-dev.dev.cs.west.us.mckesson.com", value:devVariables},
    {key: "patientrebateonline-test.dev.cs.west.us.mckesson.com", value:testVariables},
    {key: "patientrebateonline.com", value:prodVariables},
    {key: "patientrebateonline.cs.east.us.mckesson.com", value:prodVariables}
]

export const setEnvVariables = (currentPath) => {
    let configs = []
    configs = environment.find(x => currentPath.includes(x.key)).value;
    return configs;
}
