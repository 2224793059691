import React from 'react';


const PreviewProgramInformation = (props) => {
    return (

        <div className="flow-option-block">
            <h5>Program Information</h5>
            <div className="clear">
                <br></br>

                <div className="insurance-info-form">
                    <div className="clear">
                        <div className="clear">
                            <div className="fl m-block   padding-r-md m-p-0">
                                <div className="field-wrap preview-box">
                                    <div className="preview-label"> Program Name</div>
                                    <div className="preview-value"><p
                                        className="preview-text">{props.patientInfo.programName}</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

}
export default PreviewProgramInformation