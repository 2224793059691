import React, {useState} from 'react';
import {useField} from 'formik';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import DateFnsUtils from "@date-io/date-fns";


const MyDateUtils = ({label, ...props}) => {
    const [field, meta] = useField(props);
    const [err, setErr] = useState(false);
    const [xx, setXX] = useState(true);


    return (
        <>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    label={label}
                    format="MM/dd/yyyy"
                    clearable
                    disableFuture
                    value={meta.value}
                    name={props.name}
                    onChange={value => {
                        props.parentprops.setFieldValue(props.name, value, false)
                        setXX(false);
                        if (value === null || value === "Invalid Date") {
                            setErr(true)
                        } else {
                            if (value < new Date()) {
                                setErr(false)
                            } else {
                                setErr(true)
                            }
                        }
                    }}
                    onBlur={value => {
                        if (meta.value == null) {
                            setErr(true);

                        }
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                    error={err || (meta.touched && meta.error && xx) ? true : false
                    }
                    helperText={err || (meta.touched && meta.error && xx) ? props.errmsg : ""}
                />
            </MuiPickersUtilsProvider>


        </>


    );
};

export default MyDateUtils