import React from 'react';
import HomeImage from './HomeImage';
import Card from '../../component/cardlookup/Card';
import SearchCard from '../namelookup/SearchCard';
import Button from '@material-ui/core/Button';


const Home = () => {

    const [showCopayDiv, setCopayDiv] = React.useState(false);
    const [showImageDiv, setImageDiv] = React.useState(true)
    const [showSearchDiv, setSearchDiv] = React.useState(false)

    const clickMe = () => {
        setCopayDiv(true)
        setImageDiv(false)
        setSearchDiv(false)
    }

    const searchClick = () => {
        setCopayDiv(false)
        setSearchDiv(true)
        setImageDiv(false)
    }

    return (
        <div className="main-content-wrapper">
            <div className="nine-tenth-mobile block-center">
                <div className="clear">

                    <div className="m-block fl two-col padding-r-lg">
                        <h1> Welcome to RxCrossroads <br></br> by McKesson's Portal!  </h1>
                        <h4> <br></br>
                            RxCrossroads is pleased to provide you with fast, reliable assistance in obtaining medication copay saving offerings.
                        <br></br> <br></br>To help identify you in our system, please provide the following information.
                          This information will ONLY be used to validate your eligibility.
                         </h4>  <br></br>
                        <div className="m-block block-center  padding-r-xs">
                            <div className="block-center">
                                <Button id="card-btn"><span className="button btn-primaryThree"
                                                            onClick={() => clickMe()}>I know my Co-Pay Card ID</span></Button>
                            </div>
                            <br></br>
                            <div className="block-center">
                                <Button id="no-card-btn"><span className="button btn-primaryTwo"
                                                               onClick={() => searchClick()}>I want to search for my Co-Pay Card ID</span></Button>
                            </div>
                        </div>
                    </div>
                    <div className="m-block fl two-col">
                        <div>
                            {showImageDiv ? <HomeImage></HomeImage> : null}
                            {/* <div ><Card></Card></div> */}
                            {showSearchDiv ? <SearchCard></SearchCard> : null}
                            {showCopayDiv ? <Card></Card> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Home;