import React from 'react';


const StepsImageTwo = () => {

    return (

        <div className="steps2"></div>

    );

}

export default StepsImageTwo