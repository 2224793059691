import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Formik} from "formik";
//import * as yup from "yup";
import axios from "axios";
import {useDispatch} from 'react-redux'
import {SAVE_PATIENT_INFO} from "../../action/PatientLokUpAction";

import MyTextInput from "../common/MyTextInput";
import MyDateUtils from "../common/MyDateUtils";
import {setEnvVariables} from "../../config/EnvUtil";
import CircularProgress from '@material-ui/core/CircularProgress';
import {encryptFunction} from '../common/Util';
import {searchCardValidationSchema} from '../common/ValidateSchema';


const SearchCard = () => {

    let history = useHistory();
    let endpoint = setEnvVariables(window.location.hostname);

    const [serverErr, setServerErr] = useState(false);
    const [serverErrMsg, setServerErrMsg] = useState("");
    const [searchResult, setSearchResult] = useState(false);
    const [addressErrChk, setAddressErrChk] = useState(false);
    const [addressValCount, setAddressValCount] = useState(0);
    const [addressValMsg, serAddressValMsge] = useState("Please try again by selecting the correct address.");
    const [addressRadioVal, setAddressRadioVal] = useState("");
    const [addressList, setAddressList] = useState([]);
    const [startButtonDisable, setStartButtonDisable] = useState(true);
    const [unlockFlag, setUNLockFlag] = useState(true);
    const [recipientId, setRecipientId] = useState("");
    const [loadIcon, setLoadIcon] = useState(false);
    const dispatch = useDispatch();

    const nextpage = (url) => {
        setLoadIcon(true);
        let token = encryptFunction(endpoint);
        const headers = {
            'X-Copay-API-Authorization': token.data,
            'X-Copay-API-IV': token.token
        };
        axios({
            method: "POST",
            url: endpoint.lockStatus,
            headers: headers,
            data: {
                "recipientId": recipientId
            }
        })
        .then(response => {
            if (response.status === 200) {
                   history.push(url);
            } else if (response.status === 203) {
                setServerErr(true);
                setServerErrMsg(response.data.errors[0]);
            }

            setLoadIcon(false);
        })
        .catch(error => {
            console.log(error)
            setServerErr(true);
            if (error.message === "Network Error") {
                setServerErrMsg("Network Error.");
            } else {
                setServerErrMsg("Internal Server Error.");
            }
            setLoadIcon(false);
        });
    }

    const dobErr = "DOB is required in MM/DD/YYYY format and should not be future date.";


    var formSchema = {
        fname: '',
        lname: '',
        phonenum: '',
        zipcode: '',
        dob: null,
    };

    var validationSchema = searchCardValidationSchema;

    const dateFormat = (dob) => {
        var dd = dob.getDate();
        var mm = dob.getMonth() + 1;
        var yyyy = dob.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return (mm + '/' + dd + '/' + yyyy);
    }

    const addressValidation = (event) => {
        let str = event.target.value;
        let count = addressValCount;
        setAddressRadioVal(event.target.value);
        if (str.charAt(0) === 'Y') {

            if (unlockFlag) {
                setAddressErrChk(false);
                setStartButtonDisable(false);
                setAddressValCount(0);
            }

            //Enable button
        } else {
            setAddressErrChk(true);
            setStartButtonDisable(true);
            count = count + 1;
            setAddressValCount(count);
            if (count === 2) {
                serAddressValMsge("Patient record is locked, Please contact 1-866-566-6446");
                setUNLockFlag(false);
                let token = encryptFunction(endpoint);
                const headers = {
                    'X-Copay-API-Authorization': token.data,
                    'X-Copay-API-IV': token.token
                };
                axios({
                    method: "POST",
                    url: endpoint.lock,
                    headers: headers,
                    data: {
                        "recipientId": recipientId
                    }
                })
                    .then(response => {
                        //console.log(response);
                    })
                    .catch(error => {
                        console.log(error);

                    });

            }

        }
    }

    const showRandomAdresses = (addList, patientInfo) => {
        let addresses = [];
        let address;
        let id = 0;
        let correctAdress;
        addList.forEach(
            element => {
                if (element.realAddrInd === "Y") {
                    address = {
                        "id": "add_" + id,
                        "address": element.addressLine1 + " " + element.city + " " + element.state,
                        "value": element.realAddrInd + "_" + id
                    };
                    correctAdress = element;
                } else {
                    addresses.push({
                        "id": "add_" + id,
                        "address": element.addressLine1 + " " + element.city + " " + element.state,
                        "value": element.realAddrInd + "_" + id
                    });
                }
                id++;
            }
        );

        addresses = addresses.slice(0, 3);
        let x = Math.floor((Math.random() * 4) + 1);
        x = x - 1;
        setAddressList([...addresses.slice(0, x), address, ...addresses.slice(x)])


        dispatch({
            type: SAVE_PATIENT_INFO, payload: {
                "recipientId": patientInfo.recipientId,
                "email": patientInfo.email,
                "phoneNumber": patientInfo.phoneNumber,
                "programList": patientInfo.programList,
                "address": correctAdress,
                "type": "noCard",
                "orgProgCamId": '',
                "firstName": patientInfo.firstName,
                "lastName": patientInfo.lastName,
                "dob": patientInfo.dob,
                "pharmacyname": "",
                "pharmacyaddress1": "",
                "pharmacyaddress2": "",
                "pharmacycity": "",
                "pharmacystate": "",
                "pharmacyzip": "",
                "medicationname": "",
                "medicationquantity": "",
                "medicationdays": "",
                "prescriptionnum": "",
                "amountpaid": "",
                "reciptdate": null,
                "eventLookup": false,
                "opcEventId": "",
                "cardId": "",
                "maxQty": patientInfo.maxQty,
                "maxDaysSupplied": patientInfo.maxDaysSupplied,
                "activeDate": patientInfo.activeDate
            }
        });

    }

    return (
        <Formik
            initialValues={formSchema}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                setLoadIcon(true);
                let token = encryptFunction(endpoint);
                const headers = {
                    'X-Copay-API-Authorization': token.data,
                    'X-Copay-API-IV': token.token
                };
                axios({
                    method: "POST",
                    url: endpoint.name,
                    headers: headers,
                    data: {
                        "firstName": values.fname,
                        "lastName": values.lname,
                        "phoneNumber": values.phonenum,
                        "zipCode": values.zipcode,
                        "dateOfBirth": dateFormat(values.dob)
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            setServerErr(false);
                            setSearchResult(true);
                            setRecipientId(response.data.recipientId);
                            let patientInfo = {
                                "email": response.data.email,
                                "phoneNumber": response.data.phoneNumber,
                                "programList": response.data.programList,
                                "firstName": response.data.firstName,
                                "lastName": response.data.lastName,
                                "dob": response.data.dob,
                                "recipientId": response.data.recipientId,
                                "opcEventId": response.data.opcEventId,
                                "maxQty": response.data.maxQty,
                                "maxDaysSupplied": response.data.maxDaysSupplied,
                                "activeDate": response.data.activeDate,
                            };
                            showRandomAdresses(response.data.addressList, patientInfo);
                        } else if (response.status === 203) {
                            setServerErr(true);
                            setServerErrMsg(response.data.errors[0]);
                            //Needs to update with Server message
                        }
                        actions.setSubmitting(false);
                        setLoadIcon(false);
                    })
                    .catch(error => {
                        console.log(error)
                        setServerErr(true);
                        if (error.message === "Network Error") {
                            setServerErrMsg("Network Error.");
                        } else {
                            setServerErrMsg("Internal Server Error.");
                        }

                        //Needs to update with Server message
                        actions.setSubmitting(false);
                        setLoadIcon(false);
                    });

            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <div className="flow-option-block">
                        <h5>I want to search for my Co-Pay Card ID</h5>

                        {serverErr ?
                            <span style={{color: "red"}}> {serverErrMsg} </span>
                            :
                            <></>
                        }
                        <MyTextInput label="Patient First Name" name="fname" id="fname"/>
                        <MyTextInput label="Patient Last Name" name="lname"/>
                        <MyTextInput label="Patient Phone Number" name="phonenum"/>
                        <MyTextInput label="Patient Zip Code" name="zipcode"/>
                        <MyDateUtils label="Patient Date of Birth (MM/DD/YYYY)" name="dob" errmsg={dobErr}
                                     parentprops={props}/>

                        {
                            loadIcon ? <>
                                <div className="m-block text-center"><br></br><CircularProgress/></div>
                            </> : <></>
                        }

                        {searchResult ?
                            <>
                                <br></br><br></br>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Confirm Patient Address</FormLabel>
                                    {addressErrChk ?
                                        <span style={{color: "red"}}> {addressValMsg} </span>
                                        :
                                        <></>
                                    }
                                    <RadioGroup aria-label="gender" name="addressRadioGrp" onChange={addressValidation}
                                                value={addressRadioVal}>
                                        {addressList.map((value) => (
                                            <FormControlLabel value={value.value} key={value.id}
                                                              control={<Radio color="primary"/>} label={value.address}/>

                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </>
                            :
                            <> </>
                        }
                        <br></br><br></br>
                        {searchResult ?
                            <Button name="startRebateProcessbtn" onClick={() => nextpage(`/web/PatientCertification`)}
                                    disabled={startButtonDisable}>
                                {startButtonDisable ?
                                    <span className="button btn-disable"> SUBMIT</span>
                                    :
                                    <span className="button btn-primary"> SUBMIT</span>
                                } </Button>
                            :
                            <Button name="searchbtn" type="submit"> <span className="button btn-primary"> Search</span>
                            </Button>
                        }
                        <br></br><br></br>


                    </div>
                </form>

            )}
        </Formik>

    );
}


export default SearchCard



















