import React, {useRef, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import StepsImageThree from '../common/StepsImageThree'
import PreviewProgramInformation from './PreviewProgramInformation';
import PreviewPatientInformation from './PreviewPatientInformation';
import PreviewPharmacyInformation from './PreviewPharmacyInformation';
import PreviewPrescriptionInformation from './PreviewPrescriptionInformation';
import PreviewAttest from './PreviewAttest';
import Button from '@material-ui/core/Button';
import PreviewUpload from './PreviewUpload';
import {useSelector} from 'react-redux';
import {setEnvVariables} from "../../config/EnvUtil";
import Dialog from '@material-ui/core/Dialog';
import PreviewAndSubmitPrint from './PreviewAndSubmitPrint';
import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {encryptFunction} from '../common/Util';

const PreviewAndSubmit = () => {
    let history = useHistory();
    const patientInfo = useSelector(state => state.patientInfo);
    let endpoint = setEnvVariables(window.location.hostname);
    const [loadIcon, setLoadIcon] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");
    const [errorChk, setErrorChk] = useState(false);
    const [successChk, setSuccessChk] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [submitBtn, setSubmitBtn] = React.useState(true);
    const location = useLocation();
    let submitChk = true;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const nextpage = (url) => {
        //history.push(url);
        history.push({
            pathname: url,
            search: location.search,
            state: {detail: 'some_value'}
        });
        window.location.href = "#root";
    }


    //  const submitAnother = () => history.push('/web');

    const submitAnother = () => window.location.href = "/web/";

    const completeRebateSubmit = () => {
        const formData = new FormData();
        setSuccessChk(false);
        setErrorChk(false);
        setLoadIcon(true);
        handleClickOpen();

        let patientInformation = {
            "firstName": patientInfo.firstName,
            "lastName": patientInfo.lastName,
            "dob": patientInfo.dob,
            "addressLine1": patientInfo.address.addressLine1,
            "addressLine2": patientInfo.address.addressLine2,
            "city": patientInfo.address.city,
            "state": patientInfo.address.state,
            "zip": patientInfo.address.zip,
            "phoneNumber": patientInfo.phoneNumber,
            "email": patientInfo.email
        }

        let pharmacyInfo = {
        }
        let prescriptionInfo = {
        }

        if(patientInfo.flowName !== 'UPLOAD_RECEIPT'){
           console.log('patientInfo.flowName - '+patientInfo.flowName);
            pharmacyInfo = {
                "name": patientInfo.pharmacyname,
                "address1": patientInfo.pharmacyaddress1,
                "address2": patientInfo.pharmacyaddress2,
                "city": patientInfo.pharmacycity,
                "state": patientInfo.pharmacystate,
                "zip": patientInfo.pharmacyzip
            }
             prescriptionInfo = {
                "name": patientInfo.medicationname,
                "number": patientInfo.prescriptionnum,
                "quantity": patientInfo.medicationquantity,
                "daysSupplied": patientInfo.medicationdays,
                "amount": patientInfo.amountpaid,
                "receiptDate": patientInfo.reciptdate
            }
        }
       
        let attestInfo = {
            "patientSignature": patientInfo.signature,
            "relationshipToPatient": patientInfo.relationship,
            "dateOfSignature": patientInfo.signdate,
            "fontValue": patientInfo.fontValue
        }
        let opcEventIdLocal = {
        }

     
        opcEventIdLocal = patientInfo.opcEventId;

        if(patientInfo.flowName !== 'UPLOAD_RECEIPT'){
            console.log('patientInfo.flowName - '+patientInfo.flowName);
            formData.append('patientCertification', new Blob([JSON.stringify({
                "orgProgCamRecipientId": patientInfo.orgProgCamRecipientId,
                "orgRecipientId": patientInfo.recipientId,
                "orgProgCamId": patientInfo.orgProgCamId,
                "onlineUpload": patientInfo.onlineUpload,
                "cardId": patientInfo.cardId,
                "opcEventId": opcEventIdLocal,
                "customerOrgId": patientInfo.customerOrgId,
                "patientInfo": patientInformation,
                "pharmacyInfo": pharmacyInfo,
                "prescriptionInfo": prescriptionInfo,
                "attestInfo": attestInfo,
                "flowName": patientInfo.flowName
            })], {
                type: "application/json"
            }));

        }else{

            console.log('UPLOAD_RECEIPT');
            formData.append('patientCertification', new Blob([JSON.stringify({
                "orgProgCamRecipientId": patientInfo.orgProgCamRecipientId,
                "orgRecipientId": patientInfo.recipientId,
                "orgProgCamId": patientInfo.orgProgCamId,
                "onlineUpload": patientInfo.onlineUpload,
                "cardId": patientInfo.cardId,
                "opcEventId": opcEventIdLocal,
                "customerOrgId": patientInfo.customerOrgId,
                "patientInfo": patientInformation,
                "attestInfo": attestInfo,
                "flowName": patientInfo.flowName
            })], {
                type: "application/json"
            }));
            
        }

        for (var i = 0; i < patientInfo.fileList.length; i++) {
            formData.append("files", patientInfo.fileList[i].file);
        }

        let token = encryptFunction(endpoint);
        const headers = {
            'X-Copay-API-Authorization': token.data,
            'X-Copay-API-IV': token.token
        };

        setSubmitBtn(false);


        if (submitChk) {
            submitChk = false;
            axios({
                method: "POST",
                url: endpoint.online,
                data: formData,
                headers: headers,
            })
                .then(response => {
                    if (response.status === 200) {
                        setLoadIcon(false);
                        setSuccessChk(true);
                        setSubmitBtn(false);
                    } else {
                        setLoadIcon(false);
                        setErrorChk(true)
                        setErrMsg(response.data.errors[0]);
                        setSubmitBtn(true);
                    }

                    //handleClickOpen();
                    submitChk = true;
                })
                .catch(error => {
                    setLoadIcon(false);
                    setErrorChk(true)
                    if (error.message === "Network Error") {
                        setErrMsg("Network Error.");
                    } else {
                        setErrMsg("An error occurred while attempting to process your request.");
                    }

                    //   handleClickOpen();
                    setSubmitBtn(true);
                    submitChk = true;

                });

        }


    }

    return (
        <div className="main-content-wrapper">
            <div className="nine-tenth-mobile block-center">


                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >

                    {

                        loadIcon ? <>
                            <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                            <div class="modal-body">
                                We have received your submission and are processing your request.<br></br>
                                <strong>Please DO NOT press the browser back or the refresh button until the process is
                                    completed.</strong> <br></br>
                                No further action is needed at this point. Please contact 1-866-566-6446 for assistance.
                            </div>

                            <div className="m-block text-center"><br></br><CircularProgress/></div>
                            <div className="m-block text-center"><br></br></div>
                        </> : <></>
                    }

                    {successChk &&
                    <>
                        <h3 class="modal-title" id="exampleModalLongTitle"></h3>
                        {(patientInfo.flowName && patientInfo.flowName !== 'UPLOAD_RECEIPT') ?
                        <div class="modal-body">
                            Your rebate request has been successfully submitted.
                        </div>
                        :
                        <div class="modal-body">
                            Your Receipts have been successfully Uploaded
                        </div>
                        }

                        <div class="modal-footer">
                            <div className="m-block block-center   ">
                                {/* <div>
                                        <Button id="onlineConfirmation" disabled><span className=""  >CONFIRMATION</span></Button>
                                    </div><br></br>*/}
                                <div>

                                    <ReactToPrint
                                        trigger={() => <Button id="onlinePrint"><span
                                            className="button btn-primary">PRINT/DOWNLOAD</span></Button>}
                                        content={() => componentRef.current}
                                    />
                                    <div style={{display: "none"}}><PreviewAndSubmitPrint ref={componentRef}
                                                                                          patientInfo={patientInfo}/>
                                    </div>


                                </div>
                                <br></br>
                                <div>
                                    <Button id="submitAnotherOnline" onClick={submitAnother}><span
                                        className="button btn-secondary">SUBMIT ANOTHER REQUEST</span></Button>
                                </div>
                            </div>
                            <br></br>

                        </div>
                    </>
                    }

                    {errorChk &&
                    <>
                        <h3 class="modal-title error-msg" id="exampleModalLongTitle"><br></br>Submission Error&#33;  </h3>
                        <div class="modal-body error-msg">
                            {errMsg}<br></br><br></br>{/* Please try again later.*/}
                        </div>
                        <div class="modal-footer">
                            <div className="m-block block-center   ">

                                <div>
                                    <Button onClick={handleClose}><span
                                        className="button btn-primary">Close</span></Button>
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </>
                    }


                </Dialog>

                <div className="m-block  padding-r-lg ">
                    <StepsImageThree></StepsImageThree><br></br>
                    <h1 className="modal-title"> PREVIEW &#38; SUBMIT </h1><br></br>
                    <h4> Please preview your information and confirm prior to submitting. You may edit the information
                        by navigating to the previous pages using the Back button.

                    </h4>  <br></br>
                    <PreviewProgramInformation patientInfo={patientInfo}></PreviewProgramInformation>
                    <PreviewPatientInformation patientInfo={patientInfo}></PreviewPatientInformation>
                    {(patientInfo.flowName && patientInfo.flowName !== 'UPLOAD_RECEIPT') ?

                    <>
                        <PreviewPharmacyInformation patientInfo={patientInfo}></PreviewPharmacyInformation>
                        <PreviewPrescriptionInformation patientInfo={patientInfo}></PreviewPrescriptionInformation>
                    </>
                        :
                        <></>
                    }
                    <PreviewUpload patientInfo={patientInfo}></PreviewUpload>
                    <PreviewAttest patientInfo={patientInfo}></PreviewAttest>


                    <div className="m-block block-center   ">
                        <div className="m-block">
                            {submitBtn &&
                            <Button onClick={() => completeRebateSubmit()} name="previewsubmit"><span
                                className="button btn-primary">SUBMIT</span></Button>
                            }
                        </div>
                    </div>
                    <br></br>
                    <div className="m-block block-center   ">
                        <div className="m-block">
                            {submitBtn &&
                            <Button onClick={() => nextpage('/web/UploadAndAttest')} name="previewback"><span
                                className="button btn-secondary">BACK</span></Button>
                            }
                        </div>
                    </div>
                    <br></br><br></br><br></br><br></br><br></br>

                </div>
            </div>
        </div>
    );
}

export default PreviewAndSubmit