import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    clear: 'both',
  }
});

function benefitTableData(cardid, group, bin, pcn, filldate, paid, covered) {
  return { cardid, group, bin, pcn, filldate, paid, covered };
}



function SimpleTable1({patientInfo, progDataList, eventLookupCardId}) {

  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table classcardid={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableCell className="table-header-cell no-border-left">Co-pay Card ID</TableCell>
            <TableCell className="table-header-cell" align="right">Group</TableCell>
            <TableCell className="table-header-cell" align="right">BIN</TableCell>
            <TableCell className="table-header-cell" align="right">PCN</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { patientInfo.programList && eventLookupCardId === "" ?
            patientInfo.programList.map((row) => (
            <TableRow key={row.cardId}>
              <TableCell className="no-border-left table-cell" component="th" scope="row">
              {row.cardId}
              </TableCell>
              <TableCell className="table-cell" align="right">{row.group}</TableCell>
              <TableCell className="table-cell" align="right">{row.bin}</TableCell>
              <TableCell className="table-cell" align="right">{row.pcn}</TableCell>
            </TableRow>
            ))

          :
          
          //progDataList.map((row) => (
            <TableRow key={progDataList.cardId}>
              <TableCell className="no-border-left table-cell" component="th" scope="row">
              {progDataList.cardId}
              </TableCell>
              <TableCell className="table-cell" align="right">{progDataList.group}</TableCell>
              <TableCell className="table-cell" align="right">{progDataList.bin}</TableCell>
              <TableCell className="table-cell" align="right">{progDataList.pcn}</TableCell>
            </TableRow>
            //))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
function SimpleTable2({patientInfo, eventLookup, progDataList, eventLookupCardId}) {
  const classes = useStyles();
  const claimsData = [];
  
  //patientInfo.programList.map((row) => claimsData.push(...row));
  // console.log(eventLookupCardId);
  // console.log( typeof eventLookupCardId);
  // console.log((patientInfo));
  // console.log((progDataList));


  return (
    
     eventLookupCardId === "" && patientInfo !== "" && patientInfo.type === "card" && patientInfo.programList !== "" && patientInfo.programList[0].patientProgramClaimsData !== "" && patientInfo.programList[0].patientProgramClaimsData.length > 0 ?

              <TableContainer className={classes.root} component={Paper}>
                  <Table classcardid={classes.table} aria-label="simple table">
                  <TableHead>
                      <TableRow className="table-header">
                         <TableCell className="table-header-cell no-border-left">Date of Fill/Date of Service</TableCell>
                          <TableCell className="table-header-cell" align="right">You Paid</TableCell>
                         <TableCell className="table-header-cell" align="right">Program Covered</TableCell>
                        </TableRow>
                  </TableHead>
                  <TableBody>

                          {patientInfo.type === "card" && patientInfo.programList && eventLookupCardId === "" &&
                                    patientInfo.programList.map((row) => (row.patientProgramClaimsData.map((eleVal) => (
                                    <TableRow key={eleVal.dateOfFill}>
                                    <TableCell className="no-border-left table-cell" component="th" scope="row">
                                        {eleVal.dateOfFill}
                                    </TableCell>
                                    <TableCell className="table-cell" align="right">{eleVal.amountPaid}</TableCell>
                                    <TableCell className="table-cell" align="right">{eleVal.programCovered}</TableCell>
                                    </TableRow>
                                    ))))
                            }
        
                  </TableBody>
                  </Table>
                </TableContainer>

        : 
        
        eventLookupCardId !== "" && eventLookup  && progDataList !== "" && progDataList.patientProgramClaimsData!=="" && progDataList.patientProgramClaimsData.length > 0 ?
           <TableContainer className={classes.root} component={Paper}>
                  <Table classcardid={classes.table} aria-label="simple table">
                  <TableHead>
                      <TableRow className="table-header">
                         <TableCell className="table-header-cell no-border-left">Date of Fill/Date of Service</TableCell>
                          <TableCell className="table-header-cell" align="right">You Paid</TableCell>
                         <TableCell className="table-header-cell" align="right">Program Covered</TableCell>
                        </TableRow>
                  </TableHead>
                  <TableBody>

                          {progDataList !== "" && progDataList.patientProgramClaimsData!=="" && progDataList.patientProgramClaimsData.map((claimsVal) => (
                                  <TableRow key={claimsVal.dateOfFill}>
                                      <TableCell className="no-border-left table-cell" component="th" scope="row">
                                          {claimsVal.dateOfFill}
                                      </TableCell>
                                      <TableCell className="table-cell" align="right">{claimsVal.amountPaid}</TableCell>
                                      <TableCell className="table-cell" align="right">{claimsVal.programCovered}</TableCell>
                                    </TableRow>
                                    ))
                            }
        
                  </TableBody>
                  </Table>
                </TableContainer>
        :
        
        <>
        <p style={{ color: 'red' }} >Benefit Information is not available at this time</p>
        </>

  );
}

export {
  SimpleTable1,
  SimpleTable2,
}