import React from 'react';

const Header = () => {
    // let match = useRouteMatch();
    // let history = useHistory();

    // const nextpage = (url) => {
    //     history.push(url)
    // }
    return (

        <header className="main-header ">
            {/* <div className="header">
                     <img src={headerLogo} alt="RxCrossroads Logo" width="180"  /> 
                    </div> */}

            <div className="header">
                <div className="header-wrap ">
                    <div className="logo-wrap ">
                        <a href="/web/">
                            <div className="header-logo"></div>
                        </a>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Header