import React from 'react';


const HomeImage = () => {

    return (

        <div className="imageOne"></div>

    );

}

export default HomeImage