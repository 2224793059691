import * as yup from "yup";

export const cardValidationSchema = yup.object().shape({
    cardid: yup.string()
        .required("Card ID must be 8 to 12 digits long.")
        .matches(/^[a-zA-Z0-9]+$/, "Card ID must be 8 to 12 digits long.")
        .min(8, 'Card ID must be 8 to 12 digits long.')
        .max(12, 'Card ID must be 8 to 12 digits long.'),
    dob: yup.date()
        .required("DOB is required in MM/DD/YYYY format and should not be future date.")
        .max(new Date())
});


export const searchCardValidationSchema = yup.object().shape({
    fname: yup.string()
        .required('First Name must be 2 to 35 characters long.')
        .max(35, 'First Name must be 2 to 35 characters long.')
        .min(2, 'First Name must be 2 to 35 characters long.'),
    lname: yup.string()
        .required('Last Name must be 2 to 40 characters long.')
        .max(40, 'Last Name must be 2 to 40 characters long.')
        .min(2, 'Last Name must be 2 to 40 characters long.'),
    phonenum: yup.string()
        .required("Phone Number must be 10 digits long.")
        .matches(/^[0-9]+$/, "Phone Number must be 10 digits long.")
        .min(10, 'Phone Number must be 10 digits long.')
        .max(10, 'Phone Number must be 10 digits long.'),
    zipcode: yup.string()
        .required("Zip Code must be 5 digits long.")
        .matches(/^[0-9]+$/, "Zip Code must be 5 digits long.")
        .min(5, 'Zip Code must be 5 digits long.')
        .max(5, 'Zip Code must be 5 digits long.'),
    dob: yup.date()
        .required("DOB is required in MM/DD/YYYY format and should not be future date.")
        .max(new Date())
});