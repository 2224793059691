import React, {useState} from 'react';
import {useField} from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {useSelector} from 'react-redux'
import {Grid} from '@material-ui/core';
import {setEnvVariables} from "../../config/EnvUtil";
import axios from "axios";
import {chkReadOnly} from "../common/CommonScripts";
import {encryptFunction} from '../common/Util';


const ProgramIDSelect = ({label, readOnlyFlag, eventLookupFun, setMessagesFun, ...props}) => {
    const [field, meta] = useField(props);
    const [err, setErr] = useState(false);
    const [xx, setXX] = useState(true);
    const [documentList, setDocumentList] = useState([]);
    const patientInfo = useSelector(state => state.patientInfo);
    var programList = patientInfo.programList;
    //const [value, setValue] = useState();
    let orgProgCamRecipientId, orgProgCamId, cardId, programType, flowName;
    const [errMsg, setErrMsg] = useState("");
    const [serverErr, setServerErr] = useState(false);
    let endpoint = setEnvVariables(window.location.hostname);

    const setProgDoc = (value) => {
        programList.map(program => {
                if (program.orgProgCamId === value) {
                    setDocumentList(program.documentList);
                    props.parentprops.setFieldValue("onlineUpload", program.onlineUpload, false);
                    props.parentprops.setFieldValue("orgProgCamRecipientId", program.orgProgCamRecipientId, false);
                    props.parentprops.setFieldValue("orgProgCamId", program.orgProgCamId, false);
                    props.parentprops.setFieldValue("customerOrgId", program.customerOrgId, false);
                    props.parentprops.setFieldValue("cardId", program.cardId, false);
                    orgProgCamRecipientId = program.orgProgCamRecipientId;
                    orgProgCamId = program.orgProgCamId;
                    cardId = program.cardId;
                    programType = program.programType;
                }
            }
        )
    }

    const handleChange = (event) => {
        setXX(false);
        props.parentprops.setFieldValue(props.name, event.target.value, false);
        if (event.target.value !== "") {
            setErr(false);
            setProgDoc(event.target.value);
            setServerErr(false);
            let token = encryptFunction(endpoint);
            const headers = {
                'X-Copay-API-Authorization': token.data,
                'X-Copay-API-IV': token.token
            };
            axios({
                method: "POST",
                url: endpoint.eventLookup,
                headers: headers,
                data: {
                    "orgProgCamId": orgProgCamId,
                    "orgProgCamRecipientId": orgProgCamRecipientId,
                    "cardId": cardId,
                    "programType": programType,
                    "recipientId": patientInfo.recipientId,
                    "dob": patientInfo.dob
                }
            }).then(response => {
                if (response.status === 200) {
                    props.parentprops.setFieldValue("opcEventId", response.data.opcEventId, false);
                    props.parentprops.setFieldValue("maxQty", response.data.maxQty, false);
                    props.parentprops.setFieldValue("maxDaysSupplied", response.data.maxDaysSupplied, false);
                    props.parentprops.setFieldValue("activeDate", response.data.activeDate, false);
                    props.parentprops.setFieldValue("endDated", response.data.endDated, false);
                    eventLookupFun(true);
                    setMessagesFun(response.data.maxQty, response.data.maxDaysSupplied, response.data.activeDate, cardId, response.data.programList[0]);
                } else {
                    eventLookupFun(false);
                    setServerErr(true);
                    let errorMsg = response.data.errors[0];
                    if (errorMsg.includes("We have received your submission")) {
                        errorMsg = "We are currently processing your request for the program selected. Please contact 1-866-566-6446 for further assistance.";
                    } else {
                        errorMsg = "Submission is not allowed for drug/program selected due to eligibility reasons."
                    }
                    setErrMsg(errorMsg);
                }
            }).catch(error => {
                console.log(error);
                console.log(error.message);
                setServerErr(true);
                if (error.message === "Network Error") {
                    setErrMsg("Network Error.");
                } else {
                    setErrMsg("Internal Server Error.");
                }
            });
        } else {
            setErr(true);
        }
    };

    return (
         <Grid container spacing={3}>
              <Grid item xs={6}>
                 <TextField   {...field} {...props}
                              label={label}
                              id={props.name}
                              select
                              inputProps={{readOnly: chkReadOnly(readOnlyFlag)}}
                              name={props.name}
                              value={meta.value}
                              onChange={e => {
                                  handleChange(e);
                              }}
                              helperText={(xx && meta.error) || err ? meta.error : ""}
                              error={(xx && meta.error) || err ? true : false}
                 >
                     {programList.map((state) => (
                         <MenuItem key={state.orgProgCamId} value={state.orgProgCamId} selected={true}>
                            {state.programName}
                         </MenuItem>
                     ))}
                 </TextField>
              </Grid>
              <Grid item xs={12}>
                    {
                        serverErr && <span style={{color: "red"}}> {errMsg} </span>
                    }
                   <div style={{ width: '375px', }}>
                  </div> 
             </Grid> 
         </Grid>
    )
};

export default ProgramIDSelect