import React from 'react';
import {useField} from 'formik';
import TextField from '@material-ui/core/TextField';


const chkReadOnly = (val) => {
    let chk = false;
    if (val === true) {
        chk = true;
    }
    return chk;
}

const MyTextInput = ({label, readOnlyFlag, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <TextField {...field} {...props}
                   inputProps={{readOnly: chkReadOnly(readOnlyFlag)}}
                   label={label}
                   helperText={meta.touched && meta.error ? meta.error : ""}
                   error={meta.touched && meta.error ? true : false}
        />
    );
};

export default MyTextInput