const url = "https://patientrebateonline-test.dev.cs.west.us.mckesson.com/api/";

export const variables = {
    card: url + 'card',
    lock: url + 'lock',
    lockStatus: url + 'patientLockStatus',
    name: url + 'name',
    online: url + 'online',
    validate: url + 'validate',
    eventLookup: url + 'patients/event',
    deleteFile: url + 'mail/delete/',
    mail: url + 'mail',
    dataDomainScriptId: '0190c01f-3191-7fa2-ad6e-a312399b5498-test',
    reCaptcha: "6LejWrEZAAAAAKA-kLGZAuoytLXpRPQ9waDpUEAJ",
    x1: 422839872,
    x2: 1367811704,
    x3: 8,
    y1: 1131376737,
    y2: 2035574131,
    y3: 1946157056,
    y4: 9,
    selfServiceUrl: "https://mprstest.mckesson.com/selfservice/#",
    privacyPolicyLink: "https://mprstest.mckesson.com/copayselfservice/privacypolicy.html#"
};