import React from 'react';


const StepsImageThree = () => {

    return (

        <div className="steps3"></div>

    );

}

export default StepsImageThree