import React from 'react';
import {Provider} from "react-redux";

import App from './App';
import Store from "../../store/Store";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {setEnvVariables} from "../../config/EnvUtil";


const RootApp = () => {
    let endpoint = setEnvVariables(window.location.hostname);
    // console.log(endpoint.reCaptchaKey);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={endpoint.reCaptcha}
                                 render="explicit">
            <GoogleReCaptcha onVerify={token => console.log(token)}/>

            <Provider store={Store}>
                <App/>
            </Provider>

        </GoogleReCaptchaProvider>
    );
}

export default RootApp;