import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from '../../component/home/Home';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Privacy from '../../component/layout/PrivacyPolicy';
import Faqs from '../../component/layout/Faqs';

import PatientCertification from '../patientcertification/PatientCertification';
import PharmacyandPrescriptionInfo from '../patientcertification/PharmacyandPrescriptionInfo';

import UploadAndAttest from '../fileupload/UploadAndAttest';

import PreviewAndSubmit from '../preview/PreviewAndSubmit';

import '../../uisass.scss';
import ErrorBoundary from '../../error/ErrorBoundry';
import LandingPage from "../landingpage/LandingPage";
import PatientPrescriberPage from "../patientPrescriber/PatientPrescriberPage"

const App = () => {


    return (
        <div>
            <Header></Header>
            <ErrorBoundary>
                <Router>
                    <Switch>
                        <Route path="/web/PatientCertification" exact>
                            <PatientCertification/>
                        </Route>
                        <Route path="/web/PharmacyandPrescriptionInfo" exact>
                            <PharmacyandPrescriptionInfo/>
                        </Route>
                        <Route path="/web/UploadAndAttest" exact>
                            <UploadAndAttest/>
                        </Route>
                        <Route path="/web/PreviewAndSubmit" exact>
                            <PreviewAndSubmit/>
                        </Route>
                        <Route path="/web/PrivacyPolicy" exact>
                            <Privacy/>
                        </Route>
                        <Route path="/web/Faqs" exact>
                            <Faqs/>
                        </Route>
                        <Route path="/web/Home" exact>
                            <Home/>
                        </Route>
                        <Route path="/web/PatientPrescriberPage" exact>
                            <PatientPrescriberPage/>
                        </Route>
                        <Route path="/web">
                            <Home/>
                            </Route>
                        <Route path="/">
                            <Home/>
                        </Route>

                    </Switch>
                </Router>
            </ErrorBoundary>
            <Footer></Footer>
        </div>

    );
}

export default App;