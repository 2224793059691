import React from 'react';


const PreviewUpload = (props) => {

    let files = props.patientInfo.fileList;


    return (

        <div className="flow-option-block">

            <div className="clear">

                <h3>Upload Files</h3> <br/>


                <div className="clear">
                    <div className="fl m-block   padding-r-md m-p-0">
                        <div className="field-wrap preview-box">
                            <ul className="fileList">
                                {files.map((value) => (
                                    <li key={value.id}>  {value.id} </li>

                                ))}


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PreviewUpload